export interface IScanSurveyState {
    recognitionResult: string,
    recognitionResultId: number,
    loading: boolean,
    error: string,
    success: boolean,
    create: {
        loading: boolean,
        error: string,
        success: boolean,
    },
}

//#region -----------------CRUD Models----------------------------

export interface ScanSurveyModel {
    "omrFile": string,
    "image": string
}

//#endregion

//#region // -----------------CRUD Types----------------------------

export enum ScanSurveyActionsTypes {
    POST_SCAN_SURVEY_APP = 'POST_SCAN_SURVEY_APP',
    POST_SCAN_SURVEY_APP_SUCCESS = 'POST_SCAN_SURVEY_APP_SUCCESS',
    POST_SCAN_SURVEY_APP_ERROR = 'POST_SCAN_SURVEY_APP_ERROR'
}

//#endregion

//#region // -----------------CRUD Requests----------------------------

//#region // -----------------Scan Survey Request----------------------------

export interface ScanSurveyRequest {
    type: ScanSurveyActionsTypes.POST_SCAN_SURVEY_APP
}

export interface ScanSurveyRequestSucces {
    type: ScanSurveyActionsTypes.POST_SCAN_SURVEY_APP_SUCCESS,
    data: string
    loading: boolean
    success: boolean
}

export interface ScanSurveyRequestError {
    type: ScanSurveyActionsTypes.POST_SCAN_SURVEY_APP_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#endregion

//#region // -----------------CRUD Results----------------------------

export interface IScanSurveyResult {
    data: string,
    loading: boolean,
    error: string,
    success: boolean,
}

//#endregion

export type ScanSurveyActions = ScanSurveyRequest | ScanSurveyRequestSucces | ScanSurveyRequestError;