import {
	PostLoginActionsTypes,
    IUserState,
    UserActions,
	DefaultRegisterActionsTypes
} from './types';

const initialState : IUserState = {
	isLoggedIn: false,
    user: null,
	login: {
        loading: false,
        error: '',
        success: false,
    },
	defaultRegister:{
        loading: false,
        error: '',
        success: false,
    }
};

const loginReducer = (state: IUserState = initialState, action: UserActions) => {
	switch (action.type) {
		case PostLoginActionsTypes.POST_LOGIN: {
			return {
				...state,
				login: {loading: true}
			};
		}
		case PostLoginActionsTypes.POST_LOGIN_SUCCESS: {
			const { loading} = action;
			return {
				...state,
				isLoggedIn: true,
				user: {},
				login: {loading, success: true}
			};
		}
		
		case PostLoginActionsTypes.POST_LOGIN_ERROR: {
			const { error, loading } = action;
			return {
				...state,
				login: {loading: false, success: false, error: error}
			};
		}		
		case DefaultRegisterActionsTypes.DEFAULT_REGISTER: {
			return {
				...state,
				defaultRegister: {loading: true}
			};
		}
		case DefaultRegisterActionsTypes.DEFAULT_REGISTER_SUCCESS: {
			const { loading} = action;
			return {
				...state,
				isLoggedIn: true,
				user: {},
				defaultRegister: {loading, success: true}
			};
		}
		
		case DefaultRegisterActionsTypes.DEFAULT_REGISTER_ERROR: {
			const { error, loading } = action;
			return {
				...state,
				defaultRegister: {loading: false, success: false, error: error}
			};
		}		
		default:
			return state;
	}
};

export default loginReducer;
