export interface IAnswerSheetsState {
    answerSheets:[],
    selectedAnswerSheet:{},
    selectedId: number,
    loading: boolean,
    error: string,
    success: boolean,
    create: {
        loading: boolean,
        error: string,
        success: boolean,
    },
    delete: {
        loading: boolean,
        error: string,
        success: boolean,
    }
}

//#region -----------------CRUD Models----------------------------

export interface GetAnswerSheetModel {
    id: number
}

export interface GetAnswerSheetsModel {
    id: number
}

export interface CreateAnswerSheetModel {
    "name": string,
    "questionsNumber": number,
    "numberOfBubbles": number,
    "answerKeys": string,
    "colorOfBubbles": string,
    "paperSize": string
}

export interface UpdateAnswerSheetModel {
    "name": string,
    "questionsNumber": number,
    "numberOfBubbles": number,
    "answerKeys": string,
    "colorOfBubbles": string,
    "paperSize": string
}

export interface DeleteAnswerSheetModel {
    id: number
}

//#endregion

//#region // -----------------CRUD Types----------------------------

export enum GetAnswerSheetActionsTypes {
    GET_ANSWER_SHEET = 'GET_ANSWER_SHEET',
    GET_ANSWER_SHEET_SUCCESS = 'GET_ANSWER_SHEET_SUCCESS',
    GET_ANSWER_SHEET_ERROR = 'GET_ANSWER_SHEET_ERROR'
}

export enum GetAnswerSheetsActionsTypes {
    GET_ANSWER_SHEETS = 'GET_ANSWER_SHEETS',
    GET_ANSWER_SHEETS_SUCCESS = 'GET_ANSWER_SHEETS_SUCCESS',
    GET_ANSWER_SHEETS_ERROR = 'GET_ANSWER_SHEETS_ERROR'
}

export enum CreateAnswerSheetActionsTypes {
    POST_CREATE_ANSWER_SHEET = 'POST_CREATE_ANSWER_SHEET',
    POST_CREATE_ANSWER_SHEET_SUCCESS = 'POST_CREATE_ANSWER_SHEET_SUCCESS',
    POST_CREATE_ANSWER_SHEET_ERROR = 'POST_CREATE_ANSWER_SHEET_ERROR'
}

export enum UpdateAnswerSheetActionsTypes {
    PUT_UPDATE_ANSWER_SHEET = 'PUT_UPDATE_ANSWER_SHEET',
    PUT_UPDATE_ANSWER_SHEET_SUCCESS = 'PUT_UPDATE_ANSWER_SHEET_SUCCESS',
    PUT_UPDATE_ANSWER_SHEET_ERROR = 'PUT_UPDATE_ANSWER_SHEET_ERROR'
}

export enum DeleteAnswerSheetActionsTypes {
    DELETE_ANSWER_SHEET = 'DELETE_ANSWER_SHEET',
    DELETE_ANSWER_SHEET_SUCCESS = 'DELETE_ANSWER_SHEET_SUCCESS',
    DELETE_ANSWER_SHEET_ERROR = 'DELETE_ANSWER_SHEET_ERROR'
}

//#endregion

//#region // -----------------CRUD Requests----------------------------

//#region // -----------------Get Answer Sheet Request----------------------------

export interface GetAnswerSheetRequest {
    type: GetAnswerSheetActionsTypes.GET_ANSWER_SHEET
}

export interface GetAnswerSheetRequestSucces {
    type: GetAnswerSheetActionsTypes.GET_ANSWER_SHEET_SUCCESS,
    data: IGetAnswerSheetResult
    loading: boolean;
}

export interface GetAnswerSheetRequestError {
    type: GetAnswerSheetActionsTypes.GET_ANSWER_SHEET_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#region // -----------------Get Answer Sheets Request----------------------------

export interface GetAnswerSheetsRequest {
    type: GetAnswerSheetsActionsTypes.GET_ANSWER_SHEETS
}

export interface GetAnswerSheetsRequestSucces {
    type: GetAnswerSheetsActionsTypes.GET_ANSWER_SHEETS_SUCCESS,
    data: IGetAnswerSheetsResult
    loading: boolean;
}

export interface GetAnswerSheetsRequestError {
    type: GetAnswerSheetsActionsTypes.GET_ANSWER_SHEETS_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#region // -----------------Create Answer Sheet Request----------------------------

export interface CreateAnswerSheetRequest {
    type: CreateAnswerSheetActionsTypes.POST_CREATE_ANSWER_SHEET
}

export interface CreateAnswerSheetRequestSucces {
    type: CreateAnswerSheetActionsTypes.POST_CREATE_ANSWER_SHEET_SUCCESS,
    data: ICreateAnswerSheetResult
    loading: boolean
    success: boolean
}

export interface CreateAnswerSheetRequestError {
    type: CreateAnswerSheetActionsTypes.POST_CREATE_ANSWER_SHEET_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#region // -----------------Update Answer Sheet Request----------------------------

export interface UpdateAnswerSheetRequest {
    type: UpdateAnswerSheetActionsTypes.PUT_UPDATE_ANSWER_SHEET
}

export interface UpdateAnswerSheetRequestSucces {
    type: UpdateAnswerSheetActionsTypes.PUT_UPDATE_ANSWER_SHEET_SUCCESS,
    data: IUpdateAnswerSheetResult
    loading: boolean;
}

export interface UpdateAnswerSheetRequestError {
    type: UpdateAnswerSheetActionsTypes.PUT_UPDATE_ANSWER_SHEET_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#region // -----------------Delete Answer Sheet Request----------------------------

export interface DeleteAnswerSheetRequest {
    type: DeleteAnswerSheetActionsTypes.DELETE_ANSWER_SHEET
}

export interface DeleteAnswerSheetRequestSucces {
    type: DeleteAnswerSheetActionsTypes.DELETE_ANSWER_SHEET_SUCCESS,
    data: IDeleteAnswerSheetResult
    loading: boolean;
}

export interface DeleteAnswerSheetRequestError {
    type: DeleteAnswerSheetActionsTypes.DELETE_ANSWER_SHEET_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#endregion

//#region // -----------------CRUD Results----------------------------

export interface IGetAnswerSheetResult {
    data: {},
    success: boolean,
    error: number
}
export interface IGetAnswerSheetsResult {
    data: {
        answerSheets: []
    },
    success: boolean,
    error: number
}
export interface ICreateAnswerSheetResult {
    loading: boolean,
    error: string,
    success: boolean,
}
export interface IUpdateAnswerSheetResult {
    loading: boolean,
    error: string,
    success: boolean,
}
export interface IDeleteAnswerSheetResult {
    loading: boolean,
    error: string,
    success: boolean,
}

//#endregion

export type AnswerSheetActions = GetAnswerSheetRequest | GetAnswerSheetRequestSucces | GetAnswerSheetRequestError |
                        GetAnswerSheetsRequest | GetAnswerSheetsRequestSucces | GetAnswerSheetsRequestError |
                        CreateAnswerSheetRequest | CreateAnswerSheetRequestSucces | CreateAnswerSheetRequestError |
                        UpdateAnswerSheetRequest | UpdateAnswerSheetRequestSucces | UpdateAnswerSheetRequestError |
                        DeleteAnswerSheetRequest | DeleteAnswerSheetRequestSucces | DeleteAnswerSheetRequestError;