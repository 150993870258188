import styled from 'styled-components';
import { Typography } from 'antd';
import { FONT_SIZE } from '../../../../../../styles/themes/constants';
import { Link } from 'react-router-dom';

export const Paragraph = styled(Typography.Paragraph)`
  &.ant-typography {
    margin-bottom:10;
    font-size: ${FONT_SIZE.xs};
  }
`;

export const TextLink = styled(Link)`
  &.ant-typography {
    margin-bottom:0;
    padding-left:20px;
    padding-bottom:20px;
    font-size: ${FONT_SIZE.xxs};
  }
`;