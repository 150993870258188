import { takeLatest, put, call, select } from 'redux-saga/effects';
import { apiRequest } from '../../apiRequest';
import { URLs } from '../../urls';

import { GetAssessmentActionsTypes, IGetAssessmentResult, GetAssessmentRequestSucces, GetAssessmentRequestError,
	GetAssessmentsActionsTypes, IGetAssessmentsResult, GetAssessmentsRequestSucces, GetAssessmentsRequestError,
	CreateAssessmentActionsTypes, ICreateAssessmentResult, CreateAssessmentRequestSucces, CreateAssessmentRequestError,
	UpdateAssessmentActionsTypes, IUpdateAssessmentResult, UpdateAssessmentRequestSucces, UpdateAssessmentRequestError,
	DeleteAssessmentActionsTypes, IDeleteAssessmentResult, DeleteAssessmentRequestSucces, DeleteAssessmentRequestError,
} from './types';

const id = localStorage.getItem("UserId");

function* sendGetAssessmentRequest(action: any) {
	try {
		// API call
		const response : IGetAssessmentResult = yield call(apiRequest, {
			endpoint: URLs.GET_ASSESSMENT_ENDPOINT + action.id,
			method: 'GET',
			
		});

		if (response.success == true) {
			let putData: GetAssessmentRequestSucces = {
				type: GetAssessmentActionsTypes.GET_ASSESSMENT_SUCCESS,
				data: response,
				loading: false,
			};
			yield put<GetAssessmentRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: GetAssessmentRequestError = {
				type: GetAssessmentActionsTypes.GET_ASSESSMENT_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<GetAssessmentRequestError>(putData);
		}

	} catch (e: any) {
		let putData: GetAssessmentRequestError = {
			type: GetAssessmentActionsTypes.GET_ASSESSMENT_ERROR,
			error: e,
			loading: false
		};
		yield put<GetAssessmentRequestError>(putData);
	}
}

function* sendGetAssessmentsRequest(action: any) {
	try {
		// API call
		const response : IGetAssessmentsResult = yield call(apiRequest, {
			endpoint: URLs.GET_ASSESSMENTS_ENDPOINT + "/" + id,
			method: 'GET',
		});

		if (response.success == true) {
			let putData: GetAssessmentsRequestSucces = {
				type: GetAssessmentsActionsTypes.GET_ASSESSMENTS_SUCCESS,
				data: response,
				loading: false,
			};
			yield put<GetAssessmentsRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: GetAssessmentsRequestError = {
				type: GetAssessmentsActionsTypes.GET_ASSESSMENTS_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<GetAssessmentsRequestError>(putData);
		}

	} catch (e: any) {
		let putData: GetAssessmentsRequestError = {
			type: GetAssessmentsActionsTypes.GET_ASSESSMENTS_ERROR,
			error: e,
			loading: false
		};
		yield put<GetAssessmentsRequestError>(putData);
	}
}

function* sendCreateAssessmentRequest(action: any) {
	try {
		// API call
		const response : ICreateAssessmentResult = yield call(apiRequest, {
			endpoint: URLs.CREATE_ASSESSMENT_ENDPOINT + "/" + id,
			method: 'POST',
			body: action.model
		});

		if (response.success == true) {
			let putData: CreateAssessmentRequestSucces = {
				type: CreateAssessmentActionsTypes.POST_CREATE_ASSESSMENT_SUCCESS,
				data: response,
				loading: false,
				success: response.success
			};
			yield put<CreateAssessmentRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: CreateAssessmentRequestError = {
				type: CreateAssessmentActionsTypes.POST_CREATE_ASSESSMENT_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<CreateAssessmentRequestError>(putData);
		}

	} catch (e: any) {
		let putData: CreateAssessmentRequestError = {
			type: CreateAssessmentActionsTypes.POST_CREATE_ASSESSMENT_ERROR,
			error: e,
			loading: false
		};
		yield put<CreateAssessmentRequestError>(putData);
	}
}

function* sendUpdateAssessmentRequest(action: any) {
	try {
		// API call
		const response : IUpdateAssessmentResult = yield call(apiRequest, {
			endpoint: URLs.UPDATE_ASSESSMENT_ENDPOINT,
			method: 'PUT',
			body: action.model
		});

		if (response.success == true) {
			let putData: UpdateAssessmentRequestSucces = {
				type: UpdateAssessmentActionsTypes.PUT_UPDATE_ASSESSMENT_SUCCESS,
				data: response,
				loading: false,
			};
			yield put<UpdateAssessmentRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: UpdateAssessmentRequestError = {
				type: UpdateAssessmentActionsTypes.PUT_UPDATE_ASSESSMENT_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<UpdateAssessmentRequestError>(putData);
		}

	} catch (e: any) {
		let putData: UpdateAssessmentRequestError = {
			type: UpdateAssessmentActionsTypes.PUT_UPDATE_ASSESSMENT_ERROR,
			error: e,
			loading: false
		};
		yield put<UpdateAssessmentRequestError>(putData);
	}
}

function* sendDeleteAssessmentRequest(action: any) {
	try {
		// API call
		const response : IDeleteAssessmentResult = yield call(apiRequest, {
			endpoint: URLs.DELETE_ASSESSMENT_ENDPOINT,
			method: 'DELETE',
			body: action.model
		});

		if (response.success == true) {
			let putData: DeleteAssessmentRequestSucces = {
				type: DeleteAssessmentActionsTypes.DELETE_ASSESSMENT_SUCCESS,
				data: response,
				loading: false,
			};
			yield put<DeleteAssessmentRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: DeleteAssessmentRequestError = {
				type: DeleteAssessmentActionsTypes.DELETE_ASSESSMENT_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<DeleteAssessmentRequestError>(putData);
		}

	} catch (e: any) {
		let putData: DeleteAssessmentRequestError = {
			type: DeleteAssessmentActionsTypes.DELETE_ASSESSMENT_ERROR,
			error: e,
			loading: false
		};
		yield put<DeleteAssessmentRequestError>(putData);
	}
}

export function* handler() {
	yield takeLatest(GetAssessmentActionsTypes.GET_ASSESSMENT, sendGetAssessmentRequest);
	yield takeLatest(GetAssessmentsActionsTypes.GET_ASSESSMENTS, sendGetAssessmentsRequest);
	yield takeLatest(CreateAssessmentActionsTypes.POST_CREATE_ASSESSMENT, sendCreateAssessmentRequest);
	yield takeLatest(UpdateAssessmentActionsTypes.PUT_UPDATE_ASSESSMENT, sendUpdateAssessmentRequest);
	yield takeLatest(DeleteAssessmentActionsTypes.DELETE_ASSESSMENT, sendDeleteAssessmentRequest);
}

