import React from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import i18n from '../../../locale/i18n';

import { PageTitle } from '../../../components/common/PageTitle/PageTitle';
import { useResponsive } from '../../../hooks/useResponsive';
import * as S from '../Legal.styles';

const href = window.location.href;
const noIndex = true;

const PaperlessPolicyPage: React.FC = (props: any) => {
    const { isDesktop } = useResponsive();
    const { t } = useTranslation();
  
    const desktopLayout = (
      <>
      <S.H1 level={1}>Paperless Policy</S.H1>
      <S.ContentRight>Last Updated: 15 June 2023</S.ContentRight>

      <S.Paragraph>OMR Quiz cares deeply about the environment and acts responsibly to avoid any negative environmental impact. As such, OMR Quiz has a strict paperless policy meaning we do not send paper to customers and aim to reduce incoming paper to our offices to as close to zero as possible.</S.Paragraph>
      <S.Paragraph>If you have any queries or concerns about our paperless policy, please contact us on 
      <a href="mailto:sales@omrquiz.com" rel="alternate">&nbsp;sales@omrquiz.com</a>
      , we'll respond within 12 hours of receiving your request (during weekdays).</S.Paragraph>
      <S.Paragraph>Due to international mail services, writing to us at our postal address can take anything up to 21 days for us to receive and reply to your message, use this email address to get a prompt response to any query.</S.Paragraph>
      
      <S.H5 level={2}>OMR Quiz is a 100% Electronic Company</S.H5>
      <S.Tab>• All documents produced while conducting our business are electronic.</S.Tab>
      <S.Tab>• All customer interaction is achieved through electronic mediums - all serviced through digital means.</S.Tab>

      <S.H5 level={2}>Paperless Office</S.H5>
      <S.Tab>• We save resources and energy by avoiding printing and the associated postage overheads, we ask all suppliers and vendors who deal with us to interact with us in this manner where possible.</S.Tab>
      <S.Tab>• As well as environmental benefits, being paperless helps us keep streamline our organization. Our turnaround time when communicating and supporting our customers is very low as we have no paper overheads.</S.Tab>
      <S.Tab>• We don’t use fax machines or photocopiers.</S.Tab>
      <S.TabEnd>• On the very rare occasions where letters do need to be sent to us, once processed, any paper is securely destroyed or carefully recycled.</S.TabEnd>

      <S.H5 level={2}>Low Carbon Emissions</S.H5>
      <S.Paragraph>OMR Quiz is a global company. To ensure that we keep our environmental footprint low, we take steps to ensure very low carbon emissions.</S.Paragraph>
      <S.TabEnd>• Trade show and user group presence. We support trade shows and user groups via electronic prizes and financial sponsorship rather than a physical presence.</S.TabEnd>
      </>
    );
  
    const mobileAndTabletLayout = (
      <Row gutter={[20, 24]}>
        <Col span={24}>
          
        </Col>
  
        <Col span={24}>
          
        </Col>
  
        <Col span={24}>
          
        </Col>
  
        <Col span={24}>
          
        </Col>
      </Row>
    );
  
    return (
      <>
        <PageTitle title={t('head:aboutUsTitle')} description={t('head:aboutUsDescription')} url={href} noIndex={noIndex}></PageTitle>
        {isDesktop ? desktopLayout : mobileAndTabletLayout}
      </>
    );
  };

export default PaperlessPolicyPage