import { takeLatest, put, call, select } from 'redux-saga/effects';
import { apiRequest } from '../../apiRequest';
import { URLs } from '../../urls';

import { GetClassesActionsTypes, IGetClassesResult, GetClassesRequestSucces, GetClassesRequestError,
	CreateClassActionsTypes, ICreateClassResult, CreateClassRequestSucces, CreateClassRequestError,
	UpdateClassActionsTypes, IUpdateClassResult, UpdateClassRequestSucces, UpdateClassRequestError,
	DeleteClassActionsTypes, IDeleteClassResult, DeleteClassRequestSucces, DeleteClassRequestError,
} from './types';
import { getClassesRequest } from './actions';

const id = localStorage.getItem("UserId");

function* sendGetClassesRequest(action: any) {
	try {
		// API call
		const response : IGetClassesResult = yield call(apiRequest, {
			endpoint: URLs.GET_CLASSES_ENDPOINT + "/" + id,
			method: 'GET',
		});

		if (response.success == true) {
			let putData: GetClassesRequestSucces = {
				type: GetClassesActionsTypes.GET_CLASSES_SUCCESS,
				data: response,
				loading: false,
			};
			yield put<GetClassesRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: GetClassesRequestError = {
				type: GetClassesActionsTypes.GET_CLASSES_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<GetClassesRequestError>(putData);
		}

	} catch (e: any) {
		let putData: GetClassesRequestError = {
			type: GetClassesActionsTypes.GET_CLASSES_ERROR,
			error: e,
			loading: false
		};
		yield put<GetClassesRequestError>(putData);
	}
}

function* sendCreateClassRequest(action: any) {
	try {
		// API call
		const response : ICreateClassResult = yield call(apiRequest, {
			endpoint: URLs.CREATE_CLASS_ENDPOINT + "/" + id,
			method: 'POST',
			body: action.model
		});
		if (response.success == true) {
			let putData: CreateClassRequestSucces = {
				type: CreateClassActionsTypes.POST_CREATE_CLASS_SUCCESS,
				data: response,
				loading: false,
				success: response.success
			};
			yield put<CreateClassRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: CreateClassRequestError = {
				type: CreateClassActionsTypes.POST_CREATE_CLASS_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<CreateClassRequestError>(putData);
		}

	} catch (e: any) {
		let putData: CreateClassRequestError = {
			type: CreateClassActionsTypes.POST_CREATE_CLASS_ERROR,
			error: e,
			loading: false
		};
		yield put<CreateClassRequestError>(putData);
	}
}

function* sendUpdateClassRequest(action: any) {
	try {
		// API call
		const response : IUpdateClassResult = yield call(apiRequest, {
			endpoint: URLs.UPDATE_CLASS_ENDPOINT,
			method: 'PUT',
			body: action.model
		});

		if (response.success == true) {
			let putData: UpdateClassRequestSucces = {
				type: UpdateClassActionsTypes.PUT_UPDATE_CLASS_SUCCESS,
				data: response,
				loading: false,
			};
			yield put<UpdateClassRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: UpdateClassRequestError = {
				type: UpdateClassActionsTypes.PUT_UPDATE_CLASS_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<UpdateClassRequestError>(putData);
		}

	} catch (e: any) {
		let putData: UpdateClassRequestError = {
			type: UpdateClassActionsTypes.PUT_UPDATE_CLASS_ERROR,
			error: e,
			loading: false
		};
		yield put<UpdateClassRequestError>(putData);
	}
}

function* sendDeleteClassRequest(action: any) {
	try {
		// API call
		const response : IDeleteClassResult = yield call(apiRequest, {
			endpoint: URLs.DELETE_CLASS_ENDPOINT + "/" + action.id,
			method: 'DELETE',
			body: action.model
		});

		if (response.success == true) {
			let putData: DeleteClassRequestSucces = {
				type: DeleteClassActionsTypes.DELETE_CLASS_SUCCESS,
				data: response,
				loading: false,
				success: response.success
			};
			yield put<DeleteClassRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: DeleteClassRequestError = {
				type: DeleteClassActionsTypes.DELETE_CLASS_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<DeleteClassRequestError>(putData);
		}

	} catch (e: any) {
		let putData: DeleteClassRequestError = {
			type: DeleteClassActionsTypes.DELETE_CLASS_ERROR,
			error: e,
			loading: false
		};
		yield put<DeleteClassRequestError>(putData);
	}
}

export function* handler() {
	yield takeLatest(GetClassesActionsTypes.GET_CLASSES, sendGetClassesRequest);
	yield takeLatest(CreateClassActionsTypes.POST_CREATE_CLASS, sendCreateClassRequest);
	yield takeLatest(UpdateClassActionsTypes.PUT_UPDATE_CLASS, sendUpdateClassRequest);
	yield takeLatest(DeleteClassActionsTypes.DELETE_CLASS, sendDeleteClassRequest);
}

