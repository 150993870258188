import React, { useState } from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import { Button } from '../../../common/buttons/Button/Button';
import { HeaderActionWrapper } from '../../Header.styles';
import { SettingsOverlay } from './settingsOverlay/SettingsOverlay/SettingsOverlay';
import { Popover } from '../../../../components/common/Popover/Popover';

interface Props{
  color: string
}

export const SettingsDropdown = (props: Props) => {
  const [isOpened, setOpened] = useState(false);

  return (
    <Popover content={<SettingsOverlay />} trigger="click" onOpenChange={setOpened}>
      <HeaderActionWrapper>
        <Button type={isOpened ? 'ghost' : 'text'} style= {{border: 'none'}} icon={<LanguageIcon fontSize='medium' style= {{color: props.color, border: 'none'}}/>}/>
      </HeaderActionWrapper>
    </Popover>
  );
};
