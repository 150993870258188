import { Tree } from 'antd';
import type { DataNode, TreeProps } from 'antd/es/tree';
import React from 'react';
import { Link } from 'react-router-dom';
import * as S from './AboutSider.styles';
import i18n from '../../../../locale/i18n';

// const link = "/" + i18n.language;

const treeData: DataNode[] = [
  {
    title: <Link className="container" to={{ pathname: '/' }}>About us</Link>,
    key: 'sub1',
    children: [
      {
        title: <Link className="container" to={{ pathname: '/contact' }}>Contact</Link>,
        key: '1',
      }
    ],
  },
  {
    title: <Link className="container" to={{ pathname: '/legal' }}>Legal</Link>,
    key: 'sub2',
    children: [
      {
        title: <Link className="container" to={{ pathname: '/legal/tos' }}>Terms of Service</Link>,
        key: '2',
      },
      {
        title: <Link className="container" to={{ pathname: '/legal/security' }}>Security practices</Link>,
        key: '3',
      },
      {
        title: <Link className="container" to={{ pathname: '/legal/file-retention-policy' }}>File retention policy</Link>,
        key: '4',
      },
      {
        title: <Link className="container" to={{ pathname: '/legal/acceptable-use' }}>Acceptable use policy</Link>,
        key: '5',
      },
      {
        title: <Link className="container" to={{ pathname: '/legal/subprocessors' }}>Subprocessors</Link>,
        key: '6',
      },
      {
        title: <Link className="container" to={{ pathname: '/legal/copyright-notice' }}>Copyright notice</Link>,
        key: '7',
      },
      {
        title: <Link className="container" to={{ pathname: '/legal/privacy-policy' }}>Privacy Policy</Link>,
        key: '8',
      },
      {
        title: <Link className="container" to={{ pathname: '/legal/gdpr' }}>GDPR public policy</Link>,
        key: '9',
      },
      {
        title: <Link className="container" to={{ pathname: '/legal/dmca-policy' }}>DMCA policy</Link>,
        key: '10',
      },
      {
        title: <Link className="container" to={{ pathname: '/legal/paperless-policy' }}>Paperless policy</Link>,
        key: '11',
      }
    ],
  },
];

const AboutSider: React.FC = () => {
  const onSelect: TreeProps['onSelect'] = (selectedKeys, info) => {
  };
  return (
    <S.Sider>
<Tree
        defaultExpandedKeys={['sub1', 'sub2']}
        onSelect={onSelect}
        treeData={treeData}
      />
    </S.Sider>
      
  );
};

export default AboutSider;

// import React, { useState } from 'react';
// import {
//   AppstoreOutlined,
//   CalendarOutlined,
//   LinkOutlined,
//   MailOutlined,
//   SettingOutlined,
// } from '@ant-design/icons';
// import { Divider, Menu, Switch } from 'antd';
// import type { MenuProps, MenuTheme } from 'antd/es/menu';

// type MenuItem = Required<MenuProps>['items'][number];

// function getItem(
//   label: React.ReactNode,
//   key?: React.Key | null,
//   icon?: React.ReactNode,
//   children?: MenuItem[],
// ): MenuItem {
//   return {
//     key,
//     icon,
//     children,
//     label,
//   } as MenuItem;
// }

// const items: MenuItem[] = [
//   getItem('About us', 'sub1', <SettingOutlined />, [
//     getItem('Contact', '1')
//   ]),
//   getItem('Legal', 'sub2', <SettingOutlined />, [
//     getItem('Terms of Service', '2'),
//     getItem('Security practices', '3'),
//     getItem('File retention policy', '4'),
//     getItem('Acceptable use policy', '5'),
//     getItem('Subprocessors', '6'),
//     getItem('Copyright notice', '7'),
//     getItem('Privacy Policy', '8'),
//     getItem('GDPR public policy', '9'),
//     getItem('DMCA policy', '10'),
//     getItem('Paperless policy', '11')
//   ]),
//   getItem(
//     <a href="https://ant.design" rel="noopener noreferrer">
//       Ant Design
//     </a>,
//     'link',
//     <LinkOutlined />,
//   ),
// ];

// const AboutSider: React.FC = () => {
//   const [mode, setMode] = useState<'vertical' | 'inline'>('inline');
//   const [theme, setTheme] = useState<MenuTheme>('light');

//   const changeMode = (value: boolean) => {
//     setMode(value ? 'vertical' : 'inline');
//   };

//   const changeTheme = (value: boolean) => {
//     setTheme(value ? 'dark' : 'light');
//   };

//   return (
//     <>
//       <Menu
//         style={{ width: 256 }}
//         defaultSelectedKeys={['1']}
//         defaultOpenKeys={['sub1']}
//         mode={mode}
//         theme={theme}
//         items={items}
//       />
//     </>
//   );
// };

// export default AboutSider;