import {combineReducers} from '@reduxjs/toolkit';
import reducerUser from './user/reducer';
import reducerSignUp from './sign_up/reducer';
import reducerClasses from './classes/reducer';
import reducerStudents from './students/reducer';
import reducerGradingScales from './grading_scales/reducer';
import reducerAnswerSheets from './answer_sheets/reducer';
import reducerAssessments from './assessments/reducer';
import reducerGradings from './grading/reducer';

//----------------------Other apps-------------------------
import reducerCreateAnswerSheet from './create_answer_sheet/reducer';
import reducerScanAnswerSheet from './scan_answer_sheet/reducer';
import reducerCreateSurvey from './create_survey/reducer';
import reducerScanSurvey from './scan_survey/reducer';

import nightModeReducer from '../slices/nightModeSlice';
import themeReducer from '../slices/themeSlice';
import pwaReducer from '../slices/pwaSlice';

const rootReducer = combineReducers({
	user: reducerUser,
	sign_up: reducerSignUp,
	classes: reducerClasses,
	students: reducerStudents,
	grading_scales: reducerGradingScales,
	answer_sheets: reducerAnswerSheets,
	assessments: reducerAssessments,
	gradings: reducerGradings,

	create_answer_sheet: reducerCreateAnswerSheet,
	scan_answer_sheet: reducerScanAnswerSheet,
	create_survey: reducerCreateSurvey,
	scan_survey: reducerScanSurvey,
	
	nightMode: nightModeReducer,
  	theme: themeReducer,
  	pwa: pwaReducer,
});

export {rootReducer};
