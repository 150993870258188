import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18n from "./locale/i18n";
import { store } from "./store";
import { ConnectedRouter } from 'connected-react-router';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    {/* <I18nextProvider i18n={i18n}> */}
      <App />
    {/* </I18nextProvider> */}
  </Provider>
);

// ReactDOM.render(
//   // <Provider store={store}>
//   //   {/* <ConnectedRouter history={ history }> */}
//   //   <I18nextProvider i18n={i18n}>
//   //     <React.StrictMode>        
//   //       <App />
//   //     </React.StrictMode>
//   //   </I18nextProvider>
//   //   {/* </ConnectedRouter> */}
//   // </Provider>,
//   // <Provider store={store}>
//   //   <I18nextProvider i18n={i18n}>
//   //     <ConnectedRouter history={history}>
//   //       <App />
//   //     </ConnectedRouter>
//   //   </I18nextProvider>
//   // </Provider>,
//   <Provider store={store}>
//     {/* <I18nextProvider i18n={i18n}> */}
//     {/* <React.StrictMode>         */}
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//     {/* </React.StrictMode> */}
//     {/* </I18nextProvider> */}
//   </Provider>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
