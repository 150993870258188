import {
	GetAssessmentActionsTypes, GetAssessmentsActionsTypes, CreateAssessmentActionsTypes, UpdateAssessmentActionsTypes, DeleteAssessmentActionsTypes,
	IAssessmentsState,
	AssessmentActions,
} from './types';

const initialState : IAssessmentsState = {
	assessments:[],
    selectedAssessment:{},
	selectedId: -1,
    loading: false,
    error: '',
    success: false,
	create: {
        loading: false,
        error: '',
        success: false,
    },
	delete: {
        loading: false,
        error: '',
        success: false,
    }
};

const assessmentsReducer = (state: IAssessmentsState = initialState, action: AssessmentActions) => {
	switch (action.type) {
		
		// ----------------------Get Assessment Actions-----------------------
		case GetAssessmentActionsTypes.GET_ASSESSMENT: {
			return {
				...state,
				loading: true
			};
		}
		case GetAssessmentActionsTypes.GET_ASSESSMENT_SUCCESS: {
			const { loading, data} = action;
			return {
				...state,
				data: data.data,
				loading, success: true
			};
		}
		case GetAssessmentActionsTypes.GET_ASSESSMENT_ERROR: {
			const { error, loading } = action;
			return {
				...state,
				loading: false, success: false, error: error
			};
		}

		// ----------------------Get Assessments Actions-----------------------
		case GetAssessmentsActionsTypes.GET_ASSESSMENTS: {
			return {
				...state,
				loading: true
			};
		}
		case GetAssessmentsActionsTypes.GET_ASSESSMENTS_SUCCESS: {
			const { loading, data} = action;
			return {
				...state,
				loading, 
				success: true,
				assessments: data.data.assessments
			};
		}	
		case GetAssessmentsActionsTypes.GET_ASSESSMENTS_ERROR: {
			const { error, loading } = action;
			return {
				...state,
				loading: false, success: false, error: error
			};
		}				

		// ----------------------Create Assessment Actions-----------------------
		case CreateAssessmentActionsTypes.POST_CREATE_ASSESSMENT: {
			return {
				...state,
				loading: true
			};
		}
		case CreateAssessmentActionsTypes.POST_CREATE_ASSESSMENT_SUCCESS: {
			return {
				...state,
				create:{success:true, loading: false}
			};
		}	
		case CreateAssessmentActionsTypes.POST_CREATE_ASSESSMENT_ERROR: {
			return {
				...state,
				create:{success:false, loading: false}
			};
		}				

		// ----------------------Update Assessment Actions-----------------------
		case UpdateAssessmentActionsTypes.PUT_UPDATE_ASSESSMENT: {
			return {
				...state,
				loading: true
			};
		}
		case UpdateAssessmentActionsTypes.PUT_UPDATE_ASSESSMENT_SUCCESS: {
			const { loading} = action;
			return {
				...state,
				loading, success: true
			};
		}	
		case UpdateAssessmentActionsTypes.PUT_UPDATE_ASSESSMENT_ERROR: {
			const { error, loading } = action;
			return {
				...state,
				loading: false, success: false, error: error
			};
		}				

		// ----------------------Delete Assessment Actions-----------------------
		case DeleteAssessmentActionsTypes.DELETE_ASSESSMENT: {
			return {
				...state,
				loading: true
			};
		}
		case DeleteAssessmentActionsTypes.DELETE_ASSESSMENT_SUCCESS: {
			const { loading} = action;
			return {
				...state,
				loading, success: true
			};
		}	
		case DeleteAssessmentActionsTypes.DELETE_ASSESSMENT_ERROR: {
			const { error, loading } = action;
			return {
				...state,
				loading: false, success: false, error: error
			};
		}				
		default:
			return state;
	}
};

export default assessmentsReducer;
