import { takeLatest, put, call, select } from 'redux-saga/effects';
import { apiRequest } from '../../apiRequest';
import { URLs } from '../../urls';

import { GetGradingScaleActionsTypes, IGetGradingScaleResult, GetGradingScaleRequestSucces, GetGradingScaleRequestError,
	GetGradingScalesActionsTypes, IGetGradingScalesResult, GetGradingScalesRequestSucces, GetGradingScalesRequestError,
	CreateGradingScaleActionsTypes, ICreateGradingScaleResult, CreateGradingScaleRequestSucces, CreateGradingScaleRequestError,
	UpdateGradingScaleActionsTypes, IUpdateGradingScaleResult, UpdateGradingScaleRequestSucces, UpdateGradingScaleRequestError,
	DeleteGradingScaleActionsTypes, IDeleteGradingScaleResult, DeleteGradingScaleRequestSucces, DeleteGradingScaleRequestError,
} from './types';

const id = localStorage.getItem("UserId");

function* sendGetGradingScaleRequest(action: any) {
	try {
		// API call
		const response : IGetGradingScaleResult = yield call(apiRequest, {
			endpoint: URLs.GET_GRADING_SCALE_ENDPOINT + "/" + action.id,
			method: 'GET',
			
		});

		if (response.success == true) {
			let putData: GetGradingScaleRequestSucces = {
				type: GetGradingScaleActionsTypes.GET_GRADING_SCALE_SUCCESS, 
				data: response.data.gradingScaleItems,
				id: response.data.id,
				loading: false,
			};
			yield put<GetGradingScaleRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: GetGradingScaleRequestError = {
				type: GetGradingScaleActionsTypes.GET_GRADING_SCALE_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<GetGradingScaleRequestError>(putData);
		}

	} catch (e: any) {
		let putData: GetGradingScaleRequestError = {
			type: GetGradingScaleActionsTypes.GET_GRADING_SCALE_ERROR,
			error: e,
			loading: false
		};
		yield put<GetGradingScaleRequestError>(putData);
	}
}

function* sendGetGradingScalesRequest(action: any) {
	try {
		// API call
		const response : IGetGradingScalesResult = yield call(apiRequest, {
			endpoint: URLs.GET_GRADING_SCALES_ENDPOINT + "/" + id,
			method: 'GET',
		});

		if (response.success == true) {
			let putData: GetGradingScalesRequestSucces = {
				type: GetGradingScalesActionsTypes.GET_GRADING_SCALES_SUCCESS,
				data: response,
				loading: false,
			};
			yield put<GetGradingScalesRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: GetGradingScalesRequestError = {
				type: GetGradingScalesActionsTypes.GET_GRADING_SCALES_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<GetGradingScalesRequestError>(putData);
		}

	} catch (e: any) {
		let putData: GetGradingScalesRequestError = {
			type: GetGradingScalesActionsTypes.GET_GRADING_SCALES_ERROR,
			error: e,
			loading: false
		};
		yield put<GetGradingScalesRequestError>(putData);
	}
}

function* sendCreateGradingScaleRequest(action: any) {
	try {
		// API call
		const response : ICreateGradingScaleResult = yield call(apiRequest, {
			endpoint: URLs.CREATE_GRADING_SCALE_ENDPOINT + "/" + id,
			method: 'POST',
			body: action.model
		});

		if (response.success == true) {
			let putData: CreateGradingScaleRequestSucces = {
				type: CreateGradingScaleActionsTypes.POST_CREATE_GRADING_SCALE_SUCCESS,
				data: response,
				loading: false,
				success: response.success
			};
			yield put<CreateGradingScaleRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: CreateGradingScaleRequestError = {
				type: CreateGradingScaleActionsTypes.POST_CREATE_GRADING_SCALE_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<CreateGradingScaleRequestError>(putData);
		}

	} catch (e: any) {
		let putData: CreateGradingScaleRequestError = {
			type: CreateGradingScaleActionsTypes.POST_CREATE_GRADING_SCALE_ERROR,
			error: e,
			loading: false
		};
		yield put<CreateGradingScaleRequestError>(putData);
	}
}

function* sendUpdateGradingScaleRequest(action: any) {
	try {
		// API call
		const response : IUpdateGradingScaleResult = yield call(apiRequest, {
			endpoint: URLs.UPDATE_GRADING_SCALE_ENDPOINT,
			method: 'PUT',
			body: action.model
		});

		if (response.success == true) {
			let putData: UpdateGradingScaleRequestSucces = {
				type: UpdateGradingScaleActionsTypes.PUT_UPDATE_GRADING_SCALE_SUCCESS,
				data: response,
				loading: false,
			};
			yield put<UpdateGradingScaleRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: UpdateGradingScaleRequestError = {
				type: UpdateGradingScaleActionsTypes.PUT_UPDATE_GRADING_SCALE_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<UpdateGradingScaleRequestError>(putData);
		}

	} catch (e: any) {
		let putData: UpdateGradingScaleRequestError = {
			type: UpdateGradingScaleActionsTypes.PUT_UPDATE_GRADING_SCALE_ERROR,
			error: e,
			loading: false
		};
		yield put<UpdateGradingScaleRequestError>(putData);
	}
}

function* sendDeleteGradingScaleRequest(action: any) {
	try {
		// API call
		const response : IDeleteGradingScaleResult = yield call(apiRequest, {
			endpoint: URLs.DELETE_GRADING_SCALE_ENDPOINT + "/" + action.id,
			method: 'DELETE',
			body: action.model
		});
		if (response.success == true) {
			let putData: DeleteGradingScaleRequestSucces = {
				type: DeleteGradingScaleActionsTypes.DELETE_GRADING_SCALE_SUCCESS,
				data: response,
				loading: false,
				success: response.success
			};
			yield put<DeleteGradingScaleRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: DeleteGradingScaleRequestError = {
				type: DeleteGradingScaleActionsTypes.DELETE_GRADING_SCALE_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<DeleteGradingScaleRequestError>(putData);
		}

	} catch (e: any) {
		let putData: DeleteGradingScaleRequestError = {
			type: DeleteGradingScaleActionsTypes.DELETE_GRADING_SCALE_ERROR,
			error: e,
			loading: false
		};
		yield put<DeleteGradingScaleRequestError>(putData);
	}
}

export function* handler() {
	yield takeLatest(GetGradingScaleActionsTypes.GET_GRADING_SCALE, sendGetGradingScaleRequest);
	yield takeLatest(GetGradingScalesActionsTypes.GET_GRADING_SCALES, sendGetGradingScalesRequest);
	yield takeLatest(CreateGradingScaleActionsTypes.POST_CREATE_GRADING_SCALE, sendCreateGradingScaleRequest);
	yield takeLatest(UpdateGradingScaleActionsTypes.PUT_UPDATE_GRADING_SCALE, sendUpdateGradingScaleRequest);
	yield takeLatest(DeleteGradingScaleActionsTypes.DELETE_GRADING_SCALE, sendDeleteGradingScaleRequest);
}

