import React from 'react';
import { Helmet } from 'react-helmet-async';
import i18n, { allLanguages } from '../../../locale/i18n';

interface Props {
  title: string,
  description: string,
  url: string,
  noIndex: boolean,
  adSense?: boolean,
  faqLd?:
  {
    title: string,
    questions: [
      {
        question: string,
        answer: string
      }
    ]
  },
  howToLd?:
  {
    title: string,
    name: string,
    description: string,
    steps: [
      {
        title: string,
        description: string
      }
    ]
  }
}

function renderLanguage() {
  switch (i18n.language) {
    case 'en':
      return 'English';
    case 'de':
      return 'Deutsche';
    case 'el':
      return 'Ελληνικά';
    case 'es':
      return 'Española';
    case 'fi':
      return 'Suomalainen';
    case 'fr':
      return 'Français';
    case 'hi':
      return 'हिन्दी';
    case 'id':
      return 'Indonesia';
    case 'it':
      return 'Italiano';
    case 'ja':
      return '日本';
    case 'kk':
      return 'Қазақ';
    case 'ko':
      return '한국인';
    case 'ms':
      return 'Malaysia';
    case 'pl':
      return 'Polskie';
    case 'pt':
      return 'Português';
    case 'ru':
      return 'Русский';
    case 'sv':
      return 'Svensk';
    case 'tr':
      return 'Türk';
    case 'uk':
      return 'Українська';
    case 'zh':
      return '中国人';
    default:
      return 'English';
  }
}



export const PageTitle = (props: Props) => {

  const website = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    "name": `${props.title}`,
    "url": `${props.url}`,
    "description": `${props.description}`,
  };

  const faqLd = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "name": `${props.faqLd?.title}`,
    "mainEntity": 
    props.faqLd?.questions.map((el: any, index:number) => ({
      "@type": "Question",
      "name": `${el.question}`,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": `${el.answer}`,
      }
    }))
  };

  const software = {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "@id": `${props.url}`,
    "name": `${props.title}`,
    "description": `${props.description}`,
    "url": `${props.url}`,
    "aggregateRating": {
      "@type": "AggregateRating",
      "url": `${props.url}`,
      "bestRating": 5,
      "ratingValue": 4.7,
      "worstRating": 1,
      "ratingCount": 15015,
      "reviewCount": 13012
    },
    "datePublished": "2023-10-12",
    "inLanguage": { "@type": "Language", "name": renderLanguage(), "alternateName": `${i18n.language}` },
    "isAccessibleForFree": true,
    "isFamilyFriendly": true,
    "offers": { "@type": "Offer", "price": 0, "priceCurrency": "USD" },
    "applicationCategory": ["WebApplication", "BusinessApplication", "BrowserApplication"],
    "operatingSystem": ["Windows", "iOS", "Linux", "Android"],
    "softwareVersion": "231012"
  };

  const howToLd = {
    "@context": "https://schema.org",
    "@type": "HowTo",
    "name": `${props.howToLd?.title}`,
    "description": `${props.howToLd?.description}`,
    "tool": { "@type": "HowToTool", "name": "A Web browser" },
    "supply": [
      { "@type": "HowToSupply", "name": `${props.howToLd?.name}` }],
    "step": 
      props.howToLd?.steps.map((el: any, index:number) => ({
        "@type": "HowToStep", "name": `${el.title}`,
        "text": `${el.description}`,
        "position": index + 1,
        "url": `${props.url}`,
        "image": "https://www.omrquiz.com/images/image.png"
      }))
    ,
    "url": `${props.url}`,
    // "image": {
    //   "@type": "ImageObject",
    //   "url": {
    //     "@type": "URL", "@id": "https://www.omrquiz.com/images/image.png",
    //     "@context": "https://schema.org"
    //   }, "height": "300", "width": "600", "@context": "https://schema.org"
    // }
  };

  const canonical = (
    <>
      <link rel="canonical" href={props.url.split('#')[0]} />
      <link rel="alternate" hrefLang='x-default' href={i18n.language == 'en' ? props.url : props.url.replace("/" + i18n.language, '')} />
      {allLanguages.map((e) => {
        if (i18n.language != e) {
          const url = new URL(props.url);
          return (
            // <link rel="alternate" hrefLang={e} href={e == 'en' ? props.url.replace("/" + i18n.language, '') :
            //   i18n.language == 'en' ?
            //     props.url.replace(url.pathname, '/' + e + url.pathname) : props.url.replace("/" + i18n.language, '/' + e)
            // } />
            <link rel="alternate" hrefLang={e} href={e == 'en' ? props.url.replace("/" + i18n.language, '') :
              i18n.language != 'en' ? props.url.replace("/" + i18n.language, '/' + e) :
              url.pathname == '/' ? props.url.replace(props.url, props.url + e) :
                props.url.replace(url.pathname, '/' + e + url.pathname) 
            } />
          )
        }
      })}
    </>
  );

  return (
    <Helmet>
      <title>{props.title}</title>
      {canonical}
      <meta name="description" content={props.description}></meta>
      <meta property="og:type" content="website"></meta>
      <meta property="og:title" content={props.title}></meta>
      <meta property="og:description" content={props.description}></meta>
      <meta property="og:image" content="/static/assets/icons/quiz_115x110.png"></meta>
      <meta property="og:url" content={props.url}></meta>
      <meta property="og:site_name" content={i18n.t('home:headTitle')}></meta>
      <meta property="og:locale" content={i18n.language}></meta>
      <script type="application/ld+json">
        {JSON.stringify(website)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(software)}
      </script>
      {props.noIndex == true ?
        <meta name="robots" content="noindex, nofollow" /> :
        <></>
      }
      {props.adSense ?
        <script src=""></script> :
        <></>
      }
      {props.howToLd ?
        <script type="application/ld+json">
          {JSON.stringify(howToLd)}
        </script>
        :
        <></>
      }
      {props.faqLd ?
        <script type="application/ld+json">
          {JSON.stringify(faqLd)}
        </script>
        :
        <></>
      }
    </Helmet>
  );
};

