import {
	PostSignUpActionsTypes,
    ISignUpState,
    SignUpActions
} from './types';

const initialState : ISignUpState = {
    loading: false,
    error: '',
    success: false,
};

const signUpReducer = (state: ISignUpState = initialState, action: SignUpActions) => {
	switch (action.type) {
		case PostSignUpActionsTypes.POST_SIGN_UP: {
			return {
				...state,
				loading: true
			};
		}
		case PostSignUpActionsTypes.POST_SIGN_UP_SUCCESS: {
			const { loading} = action;
			return {
				...state,
				loading, success: true
			};
		}
		
		case PostSignUpActionsTypes.POST_SIGN_UP_ERROR: {
			const { error, loading } = action;
			return {
				...state,
				loading: false, success: false, error: error
			};
		}		
		default:
			return state;
	}
};

export default signUpReducer;
