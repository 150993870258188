import React, { useEffect, useLayoutEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';

import GlobalStyle from './styles/GlobalStyle';
import 'typeface-montserrat';
import 'typeface-lato';

import { history } from "./store/index";
// import { useLayoutEffect, useState } from 'react';

import { ConfigProvider } from 'antd';

import { themeObject } from './styles/themes/themeVariables';
import { withLoading } from './hocs/withLoading.hoc';
import { useAutoNightMode } from './hooks/useAutoNightMode';
//import { usePWA } from './hooks/usePWA';
import { useThemeWatcher } from './hooks/useThemeWatcher';
import { useTypedSelector } from './hooks/useTypedSelector';
import i18n from './locale/i18n';

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./assets/theme/index";

//const baseRouteUrl = "/:locale(de|el|es|fi|fr|hi|id|it|ja|kk|ko|ms|pl|pt|ru|sv|tr|uk|zh)?";
export const defaultUrl = "/";
//export const baseUrl = i18n.language === "en" ? "" : "/" + i18n.language;

import { useActions } from './hooks/useActions';
import { useLanguage } from './hooks/useLanguage';
import { AppRouter } from './AppRouter';
import { BrowserRouter, Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';

// let themeType = "dark";
// if (typeof Storage !== "undefined") {
//   // eslint-disable-line
//   themeType = localStorage.getItem("light") || "dark";
// }

const App = () => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });
  useLayoutEffect(() => history.listen(setState), [history]);

  
  //useLanguage();

  //usePWA();
  return (
    <>
      <ThemeProvider theme={theme}>
        {/* <BrowserRouter basename={defaultUrl!} location={defaultUrl!} navigator={history} navigationType={history.action}> */}
          <CssBaseline />
          <meta name="theme-color" content={"light"} />
          <GlobalStyle />
          <HelmetProvider>
            <I18nextProvider i18n={i18n}>
            <ConfigProvider >
            <Router basename={defaultUrl!} location={history.location!} navigator={history} navigationType={history.action}>
              <AppRouter />
          </Router>
            </ConfigProvider>
            </I18nextProvider>
          </HelmetProvider>
        {/* </BrowserRouter> */}
      </ThemeProvider>
    </>
  );
}

export default App;
