import React from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { PageTitle } from '../../../components/common/PageTitle/PageTitle';
import { useResponsive } from '../../../hooks/useResponsive';
import * as S from '../Legal.styles';
import i18n from '../../../locale/i18n';

const href = window.location.href;
const noIndex = true;

const AboutUsPage: React.FC = (props: any) => {
    const { isDesktop } = useResponsive();
    const { t } = useTranslation();
  
    const desktopLayout = (
      <>
      <S.H1 level={1}>About Us</S.H1>

      <S.H2 level={2}>Background</S.H2>
      <S.Paragraph>Launched in 2022 by Smallize Pty Ltd 2022 and powered by its own APIs, omrquiz.com aims to offer a wide range of cross platform apps for end users to perform countless operations on their files on any device.</S.Paragraph>
      <S.Paragraph>omrquiz.com started with web apps which users can access from any device through browser and implements tools for SEO analysis of any site on the Internet.</S.Paragraph>

      <S.H2 level={2}>Our mission</S.H2>
      <S.Paragraph>Our mission is to offer ad-free SEO apps to end users with a simple interface for easy usage. Apps will always remain free for light use.</S.Paragraph>
      <S.Paragraph>omrquiz.com carefully guards our users data and privacy. Feel free to check our security practices and file retention policy that we follow to protect our users.</S.Paragraph>
      <S.Paragraph>We provide a huge collection of tools for checking your site for SEO optimization, sitemap analysis, sitemap generation, page content analysis, page structure and much more.</S.Paragraph>
      </>
    );
  
    const mobileAndTabletLayout = (
      <Row gutter={[20, 24]}>
        <Col span={24}>
          
        </Col>
  
        <Col span={24}>
          
        </Col>
  
        <Col span={24}>
          
        </Col>
  
        <Col span={24}>
          
        </Col>
      </Row>
    );
  
    return (
      <>
        <PageTitle title={i18n.t('head:aboutUsTitle')} description={t('head:aboutUsDescription')} url={href} noIndex={noIndex}></PageTitle>
        {isDesktop ? desktopLayout : mobileAndTabletLayout}
      </>
    );
  };

export default AboutUsPage