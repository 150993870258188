import {
	GetClassActionsTypes, GetStudentActionsTypes, CreateStudentActionsTypes, UpdateStudentActionsTypes, DeleteStudentActionsTypes,
	IStudentState,
	StudentActions,
} from './types';

const initialState : IStudentState = {
	students: [],
	selectedStudent: [],
    selectedId: -1,
    loading: false,
    error: '',
    success: false,
	create: {
        loading: false,
        error: '',
        success: false,
    },
	delete: {
        loading: false,
        error: '',
        success: false,
    }
};

const studentReducer = (state: IStudentState = initialState, action: StudentActions) => {
	switch (action.type) {
		
		// ----------------------Get Class Actions-----------------------
		case GetClassActionsTypes.GET_CLASS: {
			return {
				...state,
				loading: true
			};
		}
		case GetClassActionsTypes.GET_CLASS_SUCCESS: {
			const { loading, data} = action;
			return {
				...state,
				students: data,
				selectedId: -1,
				loading, 
				success: true,
				delete:{success:false, loading: false},
				create:{success:false, loading: false},
			};
		}
		case GetClassActionsTypes.GET_CLASS_ERROR: {
			const { error, loading } = action;
			return {
				...state,
				loading: false, success: false, error: error
			};
		}
		
		// ----------------------Get Student Actions-----------------------
		case GetStudentActionsTypes.GET_STUDENT: {
			return {
				...state,
				loading: true
			};
		}
		case GetStudentActionsTypes.GET_STUDENT_SUCCESS: {
			const { loading, data, id} = action;
			return {
				...state,
				selectedStudent: data,
				selectedId: id,
				loading, success: true
			};
		}
		case GetStudentActionsTypes.GET_STUDENT_ERROR: {
			const { error, loading } = action;
			return {
				...state,
				loading: false, success: false, error: error
			};
		}

		// ----------------------Create Student Actions-----------------------
		case CreateStudentActionsTypes.POST_CREATE_STUDENT: {
			return {
				...state,
				loading: true
			};
		}
		case CreateStudentActionsTypes.POST_CREATE_STUDENT_SUCCESS: {
			return {
				...state, 
				create:{success:true, loading: false}
			};
		}	
		case CreateStudentActionsTypes.POST_CREATE_STUDENT_ERROR: {
			return {
				...state, 
				create:{success:false, loading: false}
			};
		}				

		// ----------------------Update Student Actions-----------------------
		case UpdateStudentActionsTypes.PUT_UPDATE_STUDENT: {
			return {
				...state,
				loading: true
			};
		}
		case UpdateStudentActionsTypes.PUT_UPDATE_STUDENT_SUCCESS: {
			const { loading} = action;
			return {
				...state,
				loading, success: true
			};
		}	
		case UpdateStudentActionsTypes.PUT_UPDATE_STUDENT_ERROR: {
			const { error, loading } = action;
			return {
				...state,
				loading: false, success: false, error: error
			};
		}				

		// ----------------------Delete Student Actions-----------------------
		case DeleteStudentActionsTypes.DELETE_STUDENT: {
			return {
				...state,
				loading: true
			};
		}
		case DeleteStudentActionsTypes.DELETE_STUDENT_SUCCESS: {
			const { loading} = action;
			return {
				...state,
				delete:{success:true, loading: false}
			};
		}	
		case DeleteStudentActionsTypes.DELETE_STUDENT_ERROR: {
			const { error, loading } = action;
			return {
				...state,
				loading: false, success: false, error: error
			};
		}				
		default:
			return state;
	}
};

export default studentReducer;
