/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../components/layouts/HomeLayout/components/MKBox";
import MKTypography from "../../../components/layouts/HomeLayout/components/MKTypography";

import { PageTitle } from '../../../components/common/PageTitle/PageTitle';
import { useResponsive } from '../../../hooks/useResponsive';

import DocsCard from "../../../components/layouts/DocsLayout/components/Cards/DocsCard";
import DefaultNavbar from '../../../components/layouts/HomeLayout/examples/Navbars/DefaultNavbar';
import routes from '../../../components/layouts/HomeLayout/routes';
import Footer from '../../../components/layouts/HomeLayout/examples/Footers/Footer';

import i18n from '../../../locale/i18n';

const href = window.location.href;
const noIndex = false;

function DocsPage() {
  
  return (
    <>
      <PageTitle title={i18n.t('head:docsTitle')} description={i18n.t('head:docsDescription')} url={href} noIndex={noIndex}></PageTitle>
      <DefaultNavbar
        routes={routes}
        transparent
      />
      <MKBox component="section" py={8}>
        <Container>
          <Grid container spacing={3} justifyContent="center" alignItems="center" mt={10}>
            <MKTypography variant="h1" ml={3} style={{ fontSize: 36 }}>{i18n.t('docshome:title')}</MKTypography>
            <Grid item xs={12} lg={10}>
              <MKBox mb={1}>
                <DocsCard
                  title={i18n.t('docshome:document1Title')}
                  description={i18n.t('docshome:document1Description')}
                  item={i18n.t('common:readMore')}
                  href="https://docs.omrquiz.com/introduction"
                />
              </MKBox>
              <MKBox mb={1}>
                <DocsCard
                  title={i18n.t('docshome:document2Title')}
                  description={i18n.t('docshome:document2Description')}
                  item={i18n.t('common:readMore')}
                  href="https://docs.omrquiz.com/structure-of-form"
                />
              </MKBox>
            </Grid>
          </Grid>
            <Footer/>
        </Container>
      </MKBox>
    </>
  );
};

export default DocsPage;