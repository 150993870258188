export interface ICreateSurveyState {
    survey: [],
    surveyId: number,
    loading: boolean,
    error: string,
    success: boolean,
    create: {
        loading: boolean,
        error: string,
        success: boolean,
    },
    delete: {
        loading: boolean,
        error: string,
        success: boolean,
    }
}

//#region -----------------CRUD Models----------------------------

export interface CreateSurveyModel {
    "name": string,
    "paperSize": string,
    "template": []
    //"createForm": true
}

//#endregion

//#region // -----------------CRUD Types----------------------------

export enum CreateSurveyActionsTypes {
    POST_CREATE_SURVEY_APP = 'POST_CREATE_SURVEY_APP',
    POST_CREATE_SURVEY_APP_SUCCESS = 'POST_CREATE_SURVEY_APP_SUCCESS',
    POST_CREATE_SURVEY_APP_ERROR = 'POST_CREATE_SURVEY_APP_ERROR'
}

//#endregion

//#region // -----------------CRUD Requests----------------------------

//#region // -----------------Create Survey Request----------------------------

export interface CreateSurveyRequest {
    type: CreateSurveyActionsTypes.POST_CREATE_SURVEY_APP
}

export interface CreateSurveyRequestSucces {
    type: CreateSurveyActionsTypes.POST_CREATE_SURVEY_APP_SUCCESS,
    data: string
    loading: boolean
    success: boolean
}

export interface CreateSurveyRequestError {
    type: CreateSurveyActionsTypes.POST_CREATE_SURVEY_APP_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#endregion

//#region // -----------------CRUD Results----------------------------

export interface ICreateSurveyResult {
    data: string,
    loading: boolean,
    error: string,
    success: boolean,
}

//#endregion

export type CreateSurveyActions = CreateSurveyRequest | CreateSurveyRequestSucces | CreateSurveyRequestError;