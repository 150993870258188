import {
	CreateSurveyActionsTypes, ICreateSurveyState, CreateSurveyActions,
} from './types';

const initialState : ICreateSurveyState = {
	survey: [],
	surveyId: -1,
    loading: false,
    error: '',
    success: false,
	create: {
        loading: false,
        error: '',
        success: false,
    },
	delete: {
        loading: false,
        error: '',
        success: false,
    }
};

const createSurveyReducer = (state: ICreateSurveyState = initialState, action: CreateSurveyActions) => {
	switch (action.type) {

		// ----------------------Create Survey Actions-----------------------
		case CreateSurveyActionsTypes.POST_CREATE_SURVEY_APP: {
			return {
				...state,
				loading: true
			};
		}
		case CreateSurveyActionsTypes.POST_CREATE_SURVEY_APP_SUCCESS: {
			return {
				...state,
				survey: action.data,
				create:{success:true, loading: false}
			};
		}	
		case CreateSurveyActionsTypes.POST_CREATE_SURVEY_APP_ERROR: {
			return {
				...state,
				create:{success:false, loading: false}
			};
		}				
		default:
			return state;
	}
};

export default createSurveyReducer;
