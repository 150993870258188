import { takeLatest, put, call, select } from 'redux-saga/effects';
import { apiRequest } from '../../apiRequest';
import { URLs } from '../../urls';

import { ScanSurveyActionsTypes, IScanSurveyResult, ScanSurveyRequestSucces, ScanSurveyRequestError
} from './types';

function* sendScanSurveyRequest(action: any) {
	try {
		// API call
		const response : IScanSurveyResult = yield call(apiRequest, {
			endpoint: URLs.SCAN_SURVEY_APP_ENDPOINT,
			method: 'POST',
			body: action.model
		});

		if (response.success == true) {
			let putData: ScanSurveyRequestSucces = {
				type: ScanSurveyActionsTypes.POST_SCAN_SURVEY_APP_SUCCESS,
				data: response.data,
				loading: false,
				success: response.success
			};
			yield put<ScanSurveyRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: ScanSurveyRequestError = {
				type: ScanSurveyActionsTypes.POST_SCAN_SURVEY_APP_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<ScanSurveyRequestError>(putData);
		}

	} catch (e: any) {
		let putData: ScanSurveyRequestError = {
			type: ScanSurveyActionsTypes.POST_SCAN_SURVEY_APP_ERROR,
			error: e,
			loading: false
		};
		yield put<ScanSurveyRequestError>(putData);
	}
}

export function* handler() {
	yield takeLatest(ScanSurveyActionsTypes.POST_SCAN_SURVEY_APP, sendScanSurveyRequest);
}

