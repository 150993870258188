export interface IClassesState {
    classes:[],
    loading: boolean,
    error: string,
    success: boolean,
    create: {
        loading: boolean,
        error: string,
        success: boolean,
    },
    delete: {
        loading: boolean,
        error: string,
        success: boolean,
    }
}

//#region -----------------CRUD Models----------------------------

export interface GetClassesModel {
    id: number
}

export interface CreateClassModel {
    name: string,
    faculty: string
}

export interface UpdateClassModel {
    name: string,
    faculty: string
}

export interface DeleteClassModel {
    name: string,
    faculty: string
}

//#endregion

//#region // -----------------CRUD Types----------------------------

export enum GetClassesActionsTypes {
    GET_CLASSES = 'GET_CLASSES',
    GET_CLASSES_SUCCESS = 'GET_CLASSES_SUCCESS',
    GET_CLASSES_ERROR = 'GET_CLASSES_ERROR'
}

export enum CreateClassActionsTypes {
    POST_CREATE_CLASS = 'POST_CREATE_CLASS',
    POST_CREATE_CLASS_SUCCESS = 'POST_CREATE_CLASS_SUCCESS',
    POST_CREATE_CLASS_ERROR = 'POST_CREATE_CLASS_ERROR'
}

export enum UpdateClassActionsTypes {
    PUT_UPDATE_CLASS = 'PUT_UPDATE_CLASS',
    PUT_UPDATE_CLASS_SUCCESS = 'PUT_UPDATE_CLASS_SUCCESS',
    PUT_UPDATE_CLASS_ERROR = 'PUT_UPDATE_CLASS_ERROR'
}

export enum DeleteClassActionsTypes {
    DELETE_CLASS = 'DELETE_CLASS',
    DELETE_CLASS_SUCCESS = 'DELETE_CLASS_SUCCESS',
    DELETE_CLASS_ERROR = 'DELETE_CLASS_ERROR'
}

//#endregion

//#region // -----------------CRUD Requests----------------------------

//#region // -----------------Get Classes Request----------------------------

export interface GetClassesRequest {
    type: GetClassesActionsTypes.GET_CLASSES
}

export interface GetClassesRequestSucces {
    type: GetClassesActionsTypes.GET_CLASSES_SUCCESS,
    data: IGetClassesResult
    loading: boolean;
}

export interface GetClassesRequestError {
    type: GetClassesActionsTypes.GET_CLASSES_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#region // -----------------Create Class Request----------------------------

export interface CreateClassRequest {
    type: CreateClassActionsTypes.POST_CREATE_CLASS
}

export interface CreateClassRequestSucces {
    type: CreateClassActionsTypes.POST_CREATE_CLASS_SUCCESS,
    data: ICreateClassResult
    loading: boolean
    success: boolean
}

export interface CreateClassRequestError {
    type: CreateClassActionsTypes.POST_CREATE_CLASS_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#region // -----------------Update Class Request----------------------------

export interface UpdateClassRequest {
    type: UpdateClassActionsTypes.PUT_UPDATE_CLASS
}

export interface UpdateClassRequestSucces {
    type: UpdateClassActionsTypes.PUT_UPDATE_CLASS_SUCCESS,
    data: IUpdateClassResult
    loading: boolean;
}

export interface UpdateClassRequestError {
    type: UpdateClassActionsTypes.PUT_UPDATE_CLASS_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#region // -----------------Delete Class Request----------------------------

export interface DeleteClassRequest {
    type: DeleteClassActionsTypes.DELETE_CLASS
}

export interface DeleteClassRequestSucces {
    type: DeleteClassActionsTypes.DELETE_CLASS_SUCCESS,
    data: IDeleteClassResult,
    loading: boolean,
    success: boolean
}

export interface DeleteClassRequestError {
    type: DeleteClassActionsTypes.DELETE_CLASS_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#endregion

//#region // -----------------CRUD Results----------------------------

export interface IGetClassesResult {
    data: {
        classes: []
    },
    success: boolean,
    error: number
}
export interface ICreateClassResult {
    loading: boolean,
    error: string,
    success: boolean,
}
export interface IUpdateClassResult {
    loading: boolean,
    error: string,
    success: boolean,
}
export interface IDeleteClassResult {
    loading: boolean,
    error: string,
    success: boolean,
}

//#endregion

export type ClassActions = GetClassesRequest | GetClassesRequestSucces | GetClassesRequestError |
                        CreateClassRequest | CreateClassRequestSucces | CreateClassRequestError |
                        UpdateClassRequest | UpdateClassRequestSucces | UpdateClassRequestError |
                        DeleteClassRequest | DeleteClassRequestSucces | DeleteClassRequestError;