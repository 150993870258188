import React from 'react';
import { Radio, Space } from 'antd';
import ReactCountryFlag from 'react-country-flag';
import { RadioBtn } from '../SettingsOverlay/SettingsOverlay.styles';
import i18n from '../../../../../../locale/i18n';

export const LanguagePicker: React.FC = () => {
  //const { language, setLanguage } = useLanguage();

  return (
    <Radio.Group defaultValue={i18n.language} onChange={(e) => i18n.changeLanguage(e.target.value)}>
      <Space direction="vertical">
        <RadioBtn value="en">
          <Space align="center">
          English
            <ReactCountryFlag svg countryCode="GB" />
          </Space>
        </RadioBtn>
        <RadioBtn value="de">
          <Space align="center">
          Deutsche
            <ReactCountryFlag svg countryCode="DE" />
          </Space>
        </RadioBtn>
        <RadioBtn value="el">
          <Space align="center">
          Ελληνικά
            <ReactCountryFlag svg countryCode="GR" />
          </Space>
        </RadioBtn>
        <RadioBtn value="es">
          <Space align="center">
          Española
            <ReactCountryFlag svg countryCode="ES" />
          </Space>
        </RadioBtn>
        <RadioBtn value="fi">
          <Space align="center">
          Suomalainen
            <ReactCountryFlag svg countryCode="FI" />
          </Space>
        </RadioBtn>
        <RadioBtn value="fr">
          <Space align="center">
          Français
            <ReactCountryFlag svg countryCode="FR" />
          </Space>
        </RadioBtn>
        <RadioBtn value="hi">
          <Space align="center">
          हिन्दी
            <ReactCountryFlag svg countryCode="IN" />
          </Space>
        </RadioBtn>
        <RadioBtn value="id">
          <Space align="center">
          Indonesia
            <ReactCountryFlag svg countryCode="ID" />
          </Space>
        </RadioBtn>
        <RadioBtn value="it">
          <Space align="center">
          Italiano
            <ReactCountryFlag svg countryCode="IT" />
          </Space>
        </RadioBtn>
        <RadioBtn value="ja">
          <Space align="center">
          日本
            <ReactCountryFlag svg countryCode="JP" />
          </Space>
        </RadioBtn>
        <RadioBtn value="kk">
          <Space align="center">
          Қазақ
            <ReactCountryFlag svg countryCode="KZ" />
          </Space>
        </RadioBtn>
        <RadioBtn value="ko">
          <Space align="center">
          한국인
            <ReactCountryFlag svg countryCode="KR" />
          </Space>
        </RadioBtn>
        <RadioBtn value="ms">
          <Space align="center">
          Malaysia
            <ReactCountryFlag svg countryCode="MY" />
          </Space>
        </RadioBtn>
        <RadioBtn value="pl">
          <Space align="center">
          Polskie
            <ReactCountryFlag svg countryCode="PL" />
          </Space>
        </RadioBtn>
        <RadioBtn value="pt">
          <Space align="center">
          Português
            <ReactCountryFlag svg countryCode="PT" />
          </Space>
        </RadioBtn>
        <RadioBtn value="ru">
          <Space align="center">
          Русский
            <ReactCountryFlag svg countryCode="RU" />
          </Space>
        </RadioBtn>
        <RadioBtn value="sv">
          <Space align="center">
          Svensk
            <ReactCountryFlag svg countryCode="SE" />
          </Space>
        </RadioBtn>
        <RadioBtn value="tr">
          <Space align="center">
          Türk
            <ReactCountryFlag svg countryCode="TR" />
          </Space>
        </RadioBtn>
        <RadioBtn value="uk">
          <Space align="center">
          Українська
            <ReactCountryFlag svg countryCode="UA" />
          </Space>
        </RadioBtn>
        <RadioBtn value="zh">
          <Space align="center">
          中国人
            <ReactCountryFlag svg countryCode="CN" />
          </Space>
        </RadioBtn>
      </Space>
    </Radio.Group>
  );
};
