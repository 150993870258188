export interface IAssessmentsState {
    assessments:[],
    selectedAssessment:{},
    selectedId: number,
    loading: boolean,
    error: string,
    success: boolean,
    create: {
        loading: boolean,
        error: string,
        success: boolean,
    },
    delete: {
        loading: boolean,
        error: string,
        success: boolean,
    }
}

//#region -----------------CRUD Models----------------------------

export interface GetAssessmentModel {
    id: number
}

export interface GetAssessmentsModel {
    id: number
}

export interface CreateAssessmentModel {
    "examName": string,
    "classId": number,
    "examFormId": number,
    "gradingScaleId": number,
}

export interface UpdateAssessmentModel {
    "examName": string,
    "classId": number,
    "examFormId": number,
    "gradingScaleId": number,
}

export interface DeleteAssessmentModel {
    id: number
}

//#endregion

//#region // -----------------CRUD Types----------------------------

export enum GetAssessmentActionsTypes {
    GET_ASSESSMENT = 'GET_ASSESSMENT',
    GET_ASSESSMENT_SUCCESS = 'GET_ASSESSMENT_SUCCESS',
    GET_ASSESSMENT_ERROR = 'GET_ASSESSMENT_ERROR'
}

export enum GetAssessmentsActionsTypes {
    GET_ASSESSMENTS = 'GET_ASSESSMENTS',
    GET_ASSESSMENTS_SUCCESS = 'GET_ASSESSMENTS_SUCCESS',
    GET_ASSESSMENTS_ERROR = 'GET_ASSESSMENTS_ERROR'
}

export enum CreateAssessmentActionsTypes {
    POST_CREATE_ASSESSMENT = 'POST_CREATE_ASSESSMENT',
    POST_CREATE_ASSESSMENT_SUCCESS = 'POST_CREATE_ASSESSMENT_SUCCESS',
    POST_CREATE_ASSESSMENT_ERROR = 'POST_CREATE_ASSESSMENT_ERROR'
}

export enum UpdateAssessmentActionsTypes {
    PUT_UPDATE_ASSESSMENT = 'PUT_UPDATE_ASSESSMENT',
    PUT_UPDATE_ASSESSMENT_SUCCESS = 'PUT_UPDATE_ASSESSMENT_SUCCESS',
    PUT_UPDATE_ASSESSMENT_ERROR = 'PUT_UPDATE_ASSESSMENT_ERROR'
}

export enum DeleteAssessmentActionsTypes {
    DELETE_ASSESSMENT = 'DELETE_ASSESSMENT',
    DELETE_ASSESSMENT_SUCCESS = 'DELETE_ASSESSMENT_SUCCESS',
    DELETE_ASSESSMENT_ERROR = 'DELETE_ASSESSMENT_ERROR'
}

//#endregion

//#region // -----------------CRUD Requests----------------------------

//#region // -----------------Get Assessment Request----------------------------

export interface GetAssessmentRequest {
    type: GetAssessmentActionsTypes.GET_ASSESSMENT
}

export interface GetAssessmentRequestSucces {
    type: GetAssessmentActionsTypes.GET_ASSESSMENT_SUCCESS,
    data: IGetAssessmentResult
    loading: boolean;
}

export interface GetAssessmentRequestError {
    type: GetAssessmentActionsTypes.GET_ASSESSMENT_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#region // -----------------Get Assessments Request----------------------------

export interface GetAssessmentsRequest {
    type: GetAssessmentsActionsTypes.GET_ASSESSMENTS
}

export interface GetAssessmentsRequestSucces {
    type: GetAssessmentsActionsTypes.GET_ASSESSMENTS_SUCCESS,
    data: IGetAssessmentsResult
    loading: boolean;
}

export interface GetAssessmentsRequestError {
    type: GetAssessmentsActionsTypes.GET_ASSESSMENTS_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#region // -----------------Create Assessment Request----------------------------

export interface CreateAssessmentRequest {
    type: CreateAssessmentActionsTypes.POST_CREATE_ASSESSMENT
}

export interface CreateAssessmentRequestSucces {
    type: CreateAssessmentActionsTypes.POST_CREATE_ASSESSMENT_SUCCESS,
    data: ICreateAssessmentResult
    loading: boolean
    success: boolean
}

export interface CreateAssessmentRequestError {
    type: CreateAssessmentActionsTypes.POST_CREATE_ASSESSMENT_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#region // -----------------Update Assessment Request----------------------------

export interface UpdateAssessmentRequest {
    type: UpdateAssessmentActionsTypes.PUT_UPDATE_ASSESSMENT
}

export interface UpdateAssessmentRequestSucces {
    type: UpdateAssessmentActionsTypes.PUT_UPDATE_ASSESSMENT_SUCCESS,
    data: IUpdateAssessmentResult
    loading: boolean;
}

export interface UpdateAssessmentRequestError {
    type: UpdateAssessmentActionsTypes.PUT_UPDATE_ASSESSMENT_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#region // -----------------Delete Assessment Request----------------------------

export interface DeleteAssessmentRequest {
    type: DeleteAssessmentActionsTypes.DELETE_ASSESSMENT
}

export interface DeleteAssessmentRequestSucces {
    type: DeleteAssessmentActionsTypes.DELETE_ASSESSMENT_SUCCESS,
    data: IDeleteAssessmentResult
    loading: boolean;
}

export interface DeleteAssessmentRequestError {
    type: DeleteAssessmentActionsTypes.DELETE_ASSESSMENT_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#endregion

//#region // -----------------CRUD Results----------------------------

export interface IGetAssessmentResult {
    data: {},
    success: boolean,
    error: number
}
export interface IGetAssessmentsResult {
    data: {
        assessments: []
    },
    success: boolean,
    error: number
}
export interface ICreateAssessmentResult {
    loading: boolean,
    error: string,
    success: boolean,
}
export interface IUpdateAssessmentResult {
    loading: boolean,
    error: string,
    success: boolean,
}
export interface IDeleteAssessmentResult {
    loading: boolean,
    error: string,
    success: boolean,
}

//#endregion

export type AssessmentActions = GetAssessmentRequest | GetAssessmentRequestSucces | GetAssessmentRequestError |
                        GetAssessmentsRequest | GetAssessmentsRequestSucces | GetAssessmentsRequestError |
                        CreateAssessmentRequest | CreateAssessmentRequestSucces | CreateAssessmentRequestError |
                        UpdateAssessmentRequest | UpdateAssessmentRequestSucces | UpdateAssessmentRequestError |
                        DeleteAssessmentRequest | DeleteAssessmentRequestSucces | DeleteAssessmentRequestError;