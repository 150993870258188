import { takeLatest, put, call, select } from 'redux-saga/effects';
import { apiRequest } from '../../apiRequest';
import { URLs } from '../../urls';

import { GetAnswerSheetActionsTypes, IGetAnswerSheetResult, GetAnswerSheetRequestSucces, GetAnswerSheetRequestError,
	GetAnswerSheetsActionsTypes, IGetAnswerSheetsResult, GetAnswerSheetsRequestSucces, GetAnswerSheetsRequestError,
	CreateAnswerSheetActionsTypes, ICreateAnswerSheetResult, CreateAnswerSheetRequestSucces, CreateAnswerSheetRequestError,
	UpdateAnswerSheetActionsTypes, IUpdateAnswerSheetResult, UpdateAnswerSheetRequestSucces, UpdateAnswerSheetRequestError,
	DeleteAnswerSheetActionsTypes, IDeleteAnswerSheetResult, DeleteAnswerSheetRequestSucces, DeleteAnswerSheetRequestError,
} from './types';

const id = localStorage.getItem("UserId");

function* sendGetAnswerSheetRequest(action: any) {
	try {
		// API call
		const response : IGetAnswerSheetResult = yield call(apiRequest, {
			endpoint: URLs.GET_ANSWER_SHEET_ENDPOINT + action.id,
			method: 'GET',
			
		});

		if (response.success == true) {
			let putData: GetAnswerSheetRequestSucces = {
				type: GetAnswerSheetActionsTypes.GET_ANSWER_SHEET_SUCCESS,
				data: response,
				loading: false,
			};
			yield put<GetAnswerSheetRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: GetAnswerSheetRequestError = {
				type: GetAnswerSheetActionsTypes.GET_ANSWER_SHEET_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<GetAnswerSheetRequestError>(putData);
		}

	} catch (e: any) {
		let putData: GetAnswerSheetRequestError = {
			type: GetAnswerSheetActionsTypes.GET_ANSWER_SHEET_ERROR,
			error: e,
			loading: false
		};
		yield put<GetAnswerSheetRequestError>(putData);
	}
}

function* sendGetAnswerSheetsRequest(action: any) {
	try {
		// API call
		const response : IGetAnswerSheetsResult = yield call(apiRequest, {
			endpoint: URLs.GET_ANSWER_SHEETS_ENDPOINT + "/" + id,
			method: 'GET',
		});

		if (response.success == true) {
			let putData: GetAnswerSheetsRequestSucces = {
				type: GetAnswerSheetsActionsTypes.GET_ANSWER_SHEETS_SUCCESS,
				data: response,
				loading: false,
			};
			yield put<GetAnswerSheetsRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: GetAnswerSheetsRequestError = {
				type: GetAnswerSheetsActionsTypes.GET_ANSWER_SHEETS_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<GetAnswerSheetsRequestError>(putData);
		}

	} catch (e: any) {
		let putData: GetAnswerSheetsRequestError = {
			type: GetAnswerSheetsActionsTypes.GET_ANSWER_SHEETS_ERROR,
			error: e,
			loading: false
		};
		yield put<GetAnswerSheetsRequestError>(putData);
	}
}

function* sendCreateAnswerSheetRequest(action: any) {
	try {
		// API call
		const response : ICreateAnswerSheetResult = yield call(apiRequest, {
			endpoint: URLs.CREATE_ANSWER_SHEET_ENDPOINT + "/" + id,
			method: 'POST',
			body: action.model
		});

		if (response.success == true) {
			let putData: CreateAnswerSheetRequestSucces = {
				type: CreateAnswerSheetActionsTypes.POST_CREATE_ANSWER_SHEET_SUCCESS,
				data: response,
				loading: false,
				success: response.success
			};
			yield put<CreateAnswerSheetRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: CreateAnswerSheetRequestError = {
				type: CreateAnswerSheetActionsTypes.POST_CREATE_ANSWER_SHEET_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<CreateAnswerSheetRequestError>(putData);
		}

	} catch (e: any) {
		let putData: CreateAnswerSheetRequestError = {
			type: CreateAnswerSheetActionsTypes.POST_CREATE_ANSWER_SHEET_ERROR,
			error: e,
			loading: false
		};
		yield put<CreateAnswerSheetRequestError>(putData);
	}
}

function* sendUpdateAnswerSheetRequest(action: any) {
	try {
		// API call
		const response : IUpdateAnswerSheetResult = yield call(apiRequest, {
			endpoint: URLs.UPDATE_ANSWER_SHEET_ENDPOINT,
			method: 'PUT',
			body: action.model
		});

		if (response.success == true) {
			let putData: UpdateAnswerSheetRequestSucces = {
				type: UpdateAnswerSheetActionsTypes.PUT_UPDATE_ANSWER_SHEET_SUCCESS,
				data: response,
				loading: false,
			};
			yield put<UpdateAnswerSheetRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: UpdateAnswerSheetRequestError = {
				type: UpdateAnswerSheetActionsTypes.PUT_UPDATE_ANSWER_SHEET_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<UpdateAnswerSheetRequestError>(putData);
		}

	} catch (e: any) {
		let putData: UpdateAnswerSheetRequestError = {
			type: UpdateAnswerSheetActionsTypes.PUT_UPDATE_ANSWER_SHEET_ERROR,
			error: e,
			loading: false
		};
		yield put<UpdateAnswerSheetRequestError>(putData);
	}
}

function* sendDeleteAnswerSheetRequest(action: any) {
	try {
		// API call
		const response : IDeleteAnswerSheetResult = yield call(apiRequest, {
			endpoint: URLs.DELETE_ANSWER_SHEET_ENDPOINT,
			method: 'DELETE',
			body: action.model
		});

		if (response.success == true) {
			let putData: DeleteAnswerSheetRequestSucces = {
				type: DeleteAnswerSheetActionsTypes.DELETE_ANSWER_SHEET_SUCCESS,
				data: response,
				loading: false,
			};
			yield put<DeleteAnswerSheetRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: DeleteAnswerSheetRequestError = {
				type: DeleteAnswerSheetActionsTypes.DELETE_ANSWER_SHEET_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<DeleteAnswerSheetRequestError>(putData);
		}

	} catch (e: any) {
		let putData: DeleteAnswerSheetRequestError = {
			type: DeleteAnswerSheetActionsTypes.DELETE_ANSWER_SHEET_ERROR,
			error: e,
			loading: false
		};
		yield put<DeleteAnswerSheetRequestError>(putData);
	}
}

export function* handler() {
	yield takeLatest(GetAnswerSheetActionsTypes.GET_ANSWER_SHEET, sendGetAnswerSheetRequest);
	yield takeLatest(GetAnswerSheetsActionsTypes.GET_ANSWER_SHEETS, sendGetAnswerSheetsRequest);
	yield takeLatest(CreateAnswerSheetActionsTypes.POST_CREATE_ANSWER_SHEET, sendCreateAnswerSheetRequest);
	yield takeLatest(UpdateAnswerSheetActionsTypes.PUT_UPDATE_ANSWER_SHEET, sendUpdateAnswerSheetRequest);
	yield takeLatest(DeleteAnswerSheetActionsTypes.DELETE_ANSWER_SHEET, sendDeleteAnswerSheetRequest);
}

