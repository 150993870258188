import { takeLatest, put, call, select } from 'redux-saga/effects';
import { apiRequest } from '../../apiRequest';
import { URLs } from '../../urls';

import { GetGradingActionsTypes, IGetGradingResult, GetGradingRequestSucces, GetGradingRequestError,
	GetGradingsActionsTypes, IGetGradingsResult, GetGradingsRequestSucces, GetGradingsRequestError,
	CreateGradingActionsTypes, ICreateGradingResult, CreateGradingRequestSucces, CreateGradingRequestError,
	UpdateGradingActionsTypes, IUpdateGradingResult, UpdateGradingRequestSucces, UpdateGradingRequestError,
	DeleteGradingActionsTypes, IDeleteGradingResult, DeleteGradingRequestSucces, DeleteGradingRequestError,
} from './types';

const id = localStorage.getItem("UserId");

function* sendGetGradingRequest(action: any) {
	try {
		// API call
		const response : IGetGradingResult = yield call(apiRequest, {
			endpoint: URLs.GET_GRADING_ENDPOINT + action.id,
			method: 'GET',
			
		});

		if (response.success == true) {
			let putData: GetGradingRequestSucces = {
				type: GetGradingActionsTypes.GET_GRADING_SUCCESS,
				data: response,
				loading: false,
			};
			yield put<GetGradingRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: GetGradingRequestError = {
				type: GetGradingActionsTypes.GET_GRADING_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<GetGradingRequestError>(putData);
		}

	} catch (e: any) {
		let putData: GetGradingRequestError = {
			type: GetGradingActionsTypes.GET_GRADING_ERROR,
			error: e,
			loading: false
		};
		yield put<GetGradingRequestError>(putData);
	}
}

function* sendGetGradingsRequest(action: any) {
	try {
		// API call
		const response : IGetGradingsResult = yield call(apiRequest, {
			endpoint: URLs.GET_GRADINGS_ENDPOINT + "/" + id,
			method: 'GET',
		});

		if (response.success == true) {
			let putData: GetGradingsRequestSucces = {
				type: GetGradingsActionsTypes.GET_GRADINGS_SUCCESS,
				data: response,
				loading: false,
			};
			yield put<GetGradingsRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: GetGradingsRequestError = {
				type: GetGradingsActionsTypes.GET_GRADINGS_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<GetGradingsRequestError>(putData);
		}

	} catch (e: any) {
		let putData: GetGradingsRequestError = {
			type: GetGradingsActionsTypes.GET_GRADINGS_ERROR,
			error: e,
			loading: false
		};
		yield put<GetGradingsRequestError>(putData);
	}
}

function* sendCreateGradingRequest(action: any) {
	try {
		// API call
		const response : ICreateGradingResult = yield call(apiRequest, {
			endpoint: URLs.CREATE_BATCH_RECOGNITION,
			method: 'POST',
			body: action.model
		});

		if (response.success == true) {
			let putData: CreateGradingRequestSucces = {
				type: CreateGradingActionsTypes.POST_CREATE_GRADING_SUCCESS,
				data: response,
				loading: false,
				success: response.success
			};
			yield put<CreateGradingRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: CreateGradingRequestError = {
				type: CreateGradingActionsTypes.POST_CREATE_GRADING_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<CreateGradingRequestError>(putData);
		}

	} catch (e: any) {
		let putData: CreateGradingRequestError = {
			type: CreateGradingActionsTypes.POST_CREATE_GRADING_ERROR,
			error: e,
			loading: false
		};
		yield put<CreateGradingRequestError>(putData);
	}
}

function* sendUpdateGradingRequest(action: any) {
	try {
		// API call
		const response : IUpdateGradingResult = yield call(apiRequest, {
			endpoint: URLs.UPDATE_GRADING_ENDPOINT,
			method: 'PUT',
			body: action.model
		});

		if (response.success == true) {
			let putData: UpdateGradingRequestSucces = {
				type: UpdateGradingActionsTypes.PUT_UPDATE_GRADING_SUCCESS,
				data: response,
				loading: false,
			};
			yield put<UpdateGradingRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: UpdateGradingRequestError = {
				type: UpdateGradingActionsTypes.PUT_UPDATE_GRADING_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<UpdateGradingRequestError>(putData);
		}

	} catch (e: any) {
		let putData: UpdateGradingRequestError = {
			type: UpdateGradingActionsTypes.PUT_UPDATE_GRADING_ERROR,
			error: e,
			loading: false
		};
		yield put<UpdateGradingRequestError>(putData);
	}
}

function* sendDeleteGradingRequest(action: any) {
	try {
		// API call
		const response : IDeleteGradingResult = yield call(apiRequest, {
			endpoint: URLs.DELETE_GRADING_ENDPOINT,
			method: 'DELETE',
			body: action.model
		});

		if (response.success == true) {
			let putData: DeleteGradingRequestSucces = {
				type: DeleteGradingActionsTypes.DELETE_GRADING_SUCCESS,
				data: response,
				loading: false,
			};
			yield put<DeleteGradingRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: DeleteGradingRequestError = {
				type: DeleteGradingActionsTypes.DELETE_GRADING_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<DeleteGradingRequestError>(putData);
		}

	} catch (e: any) {
		let putData: DeleteGradingRequestError = {
			type: DeleteGradingActionsTypes.DELETE_GRADING_ERROR,
			error: e,
			loading: false
		};
		yield put<DeleteGradingRequestError>(putData);
	}
}

export function* handler() {
	yield takeLatest(GetGradingActionsTypes.GET_GRADING, sendGetGradingRequest);
	yield takeLatest(GetGradingsActionsTypes.GET_GRADINGS, sendGetGradingsRequest);
	yield takeLatest(CreateGradingActionsTypes.POST_CREATE_GRADING, sendCreateGradingRequest);
	yield takeLatest(UpdateGradingActionsTypes.PUT_UPDATE_GRADING, sendUpdateGradingRequest);
	yield takeLatest(DeleteGradingActionsTypes.DELETE_GRADING, sendDeleteGradingRequest);
}

