import { takeLatest, put, call, select } from 'redux-saga/effects';
import { apiRequest } from '../../apiRequest';
import { URLs } from '../../urls';

import { CreateAnswerSheetActionsTypes, ICreateAnswerSheetResult, CreateAnswerSheetRequestSucces, CreateAnswerSheetRequestError
} from './types';

const id = localStorage.getItem("UserId");

function* sendCreateAnswerSheetRequest(action: any) {
	try {
		// API call
		const response : ICreateAnswerSheetResult = yield call(apiRequest, {
			endpoint: URLs.CREATE_ANSWER_SHEET_APP_ENDPOINT,
			method: 'POST',
			body: action.model
		});

		if (response.success == true) {
			let putData: CreateAnswerSheetRequestSucces = {
				type: CreateAnswerSheetActionsTypes.POST_CREATE_ANSWER_SHEET_APP_SUCCESS,
				data: response.data,
				loading: false,
				success: response.success
			};
			yield put<CreateAnswerSheetRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: CreateAnswerSheetRequestError = {
				type: CreateAnswerSheetActionsTypes.POST_CREATE_ANSWER_SHEET_APP_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<CreateAnswerSheetRequestError>(putData);
		}

	} catch (e: any) {
		let putData: CreateAnswerSheetRequestError = {
			type: CreateAnswerSheetActionsTypes.POST_CREATE_ANSWER_SHEET_APP_ERROR,
			error: e,
			loading: false
		};
		yield put<CreateAnswerSheetRequestError>(putData);
	}
}

export function* handler() {
	yield takeLatest(CreateAnswerSheetActionsTypes.POST_CREATE_ANSWER_SHEET_APP, sendCreateAnswerSheetRequest);
}

