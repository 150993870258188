export interface IGradingScalesState {
    gradingScales:[],
    selectedGradingScale:[],
    selectedId: number,
    loading: boolean,
    error: string,
    success: boolean,
    create: {
        loading: boolean,
        error: string,
        success: boolean,
    },
    delete: {
        loading: boolean,
        error: string,
        success: boolean,
    }
}

//#region -----------------CRUD Models----------------------------

export interface GetGradingScaleModel {
    id: number
}

export interface GetGradingScalesModel {
    id: number
}

export interface CreateGradingScaleModel {
    "name": string,
    "gradingScaleItems": [
        {
        "grade": string,
        "wrongMin": number,
        "wrongMax": number
        }
    ]
}

export interface UpdateGradingScaleModel {
    "name": string,
    "gradingScaleItems": [
        {
        "grade": string,
        "wrongMin": number,
        "wrongMax": number
        }
    ]
}

export interface DeleteGradingScaleModel {
    id: number
}

//#endregion

//#region // -----------------CRUD Types----------------------------

export enum GetGradingScaleActionsTypes {
    GET_GRADING_SCALE = 'GET_GRADING_SCALE',
    GET_GRADING_SCALE_SUCCESS = 'GET_GRADING_SCALE_SUCCESS',
    GET_GRADING_SCALE_ERROR = 'GET_GRADING_SCALE_ERROR'
}

export enum GetGradingScalesActionsTypes {
    GET_GRADING_SCALES = 'GET_GRADING_SCALES',
    GET_GRADING_SCALES_SUCCESS = 'GET_GRADING_SCALES_SUCCESS',
    GET_GRADING_SCALES_ERROR = 'GET_GRADING_SCALES_ERROR'
}

export enum CreateGradingScaleActionsTypes {
    POST_CREATE_GRADING_SCALE = 'POST_CREATE_GRADING_SCALE',
    POST_CREATE_GRADING_SCALE_SUCCESS = 'POST_CREATE_GRADING_SCALE_SUCCESS',
    POST_CREATE_GRADING_SCALE_ERROR = 'POST_CREATE_GRADING_SCALE_ERROR'
}

export enum UpdateGradingScaleActionsTypes {
    PUT_UPDATE_GRADING_SCALE = 'PUT_UPDATE_GRADING_SCALE',
    PUT_UPDATE_GRADING_SCALE_SUCCESS = 'PUT_UPDATE_GRADING_SCALE_SUCCESS',
    PUT_UPDATE_GRADING_SCALE_ERROR = 'PUT_UPDATE_GRADING_SCALE_ERROR'
}

export enum DeleteGradingScaleActionsTypes {
    DELETE_GRADING_SCALE = 'DELETE_GRADING_SCALE',
    DELETE_GRADING_SCALE_SUCCESS = 'DELETE_GRADING_SCALE_SUCCESS',
    DELETE_GRADING_SCALE_ERROR = 'DELETE_GRADING_SCALE_ERROR'
}

//#endregion

//#region // -----------------CRUD Requests----------------------------

//#region // -----------------Get Grading Scale Request----------------------------

export interface GetGradingScaleRequest {
    type: GetGradingScaleActionsTypes.GET_GRADING_SCALE
}

export interface GetGradingScaleRequestSucces {
    type: GetGradingScaleActionsTypes.GET_GRADING_SCALE_SUCCESS,
    data: {},
    id: number,
    loading: boolean
}

export interface GetGradingScaleRequestError {
    type: GetGradingScaleActionsTypes.GET_GRADING_SCALE_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#region // -----------------Get Grading Scales Request----------------------------

export interface GetGradingScalesRequest {
    type: GetGradingScalesActionsTypes.GET_GRADING_SCALES
}

export interface GetGradingScalesRequestSucces {
    type: GetGradingScalesActionsTypes.GET_GRADING_SCALES_SUCCESS,
    data: IGetGradingScalesResult
    loading: boolean;
}

export interface GetGradingScalesRequestError {
    type: GetGradingScalesActionsTypes.GET_GRADING_SCALES_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#region // -----------------Create Grading Scale Request----------------------------

export interface CreateGradingScaleRequest {
    type: CreateGradingScaleActionsTypes.POST_CREATE_GRADING_SCALE
}

export interface CreateGradingScaleRequestSucces {
    type: CreateGradingScaleActionsTypes.POST_CREATE_GRADING_SCALE_SUCCESS,
    data: ICreateGradingScaleResult
    loading: boolean
    success: boolean
}

export interface CreateGradingScaleRequestError {
    type: CreateGradingScaleActionsTypes.POST_CREATE_GRADING_SCALE_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#region // -----------------Update Grading Scale Request----------------------------

export interface UpdateGradingScaleRequest {
    type: UpdateGradingScaleActionsTypes.PUT_UPDATE_GRADING_SCALE
}

export interface UpdateGradingScaleRequestSucces {
    type: UpdateGradingScaleActionsTypes.PUT_UPDATE_GRADING_SCALE_SUCCESS,
    data: IUpdateGradingScaleResult
    loading: boolean;
}

export interface UpdateGradingScaleRequestError {
    type: UpdateGradingScaleActionsTypes.PUT_UPDATE_GRADING_SCALE_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#region // -----------------Delete Grading Scale Request----------------------------

export interface DeleteGradingScaleRequest {
    type: DeleteGradingScaleActionsTypes.DELETE_GRADING_SCALE
}

export interface DeleteGradingScaleRequestSucces {
    type: DeleteGradingScaleActionsTypes.DELETE_GRADING_SCALE_SUCCESS,
    data: IDeleteGradingScaleResult
    loading: boolean
    success: boolean
}

export interface DeleteGradingScaleRequestError {
    type: DeleteGradingScaleActionsTypes.DELETE_GRADING_SCALE_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#endregion

//#region // -----------------CRUD Results----------------------------

export interface IGetGradingScaleResult {
    data: {
        id: number,
        gradingScaleItems: []
    },
    success: boolean,
    error: number
}
export interface IGetGradingScalesResult {
    data: {
        gradingScales: []
    },
    success: boolean,
    error: number
}
export interface ICreateGradingScaleResult {
    loading: boolean,
    error: string,
    success: boolean,
}
export interface IUpdateGradingScaleResult {
    loading: boolean,
    error: string,
    success: boolean,
}
export interface IDeleteGradingScaleResult {
    loading: boolean,
    error: string,
    success: boolean,
}

//#endregion

export type GradingScaleActions = GetGradingScaleRequest | GetGradingScaleRequestSucces | GetGradingScaleRequestError |
                        GetGradingScalesRequest | GetGradingScalesRequestSucces | GetGradingScalesRequestError |
                        CreateGradingScaleRequest | CreateGradingScaleRequestSucces | CreateGradingScaleRequestError |
                        UpdateGradingScaleRequest | UpdateGradingScaleRequestSucces | UpdateGradingScaleRequestError |
                        DeleteGradingScaleRequest | DeleteGradingScaleRequestSucces | DeleteGradingScaleRequestError;