import {
	CreateAnswerSheetActionsTypes, ICreateAnswerSheetState, CreateAnswerSheetActions,
} from './types';

const initialState : ICreateAnswerSheetState = {
	answerSheet: [],
	answerSheetId: -1,
    loading: false,
    error: '',
    success: false,
	create: {
        loading: false,
        error: '',
        success: false,
    },
	delete: {
        loading: false,
        error: '',
        success: false,
    }
};

const createAswerSheetReducer = (state: ICreateAnswerSheetState = initialState, action: CreateAnswerSheetActions) => {
	switch (action.type) {

		// ----------------------Create Answer Sheet Actions-----------------------
		case CreateAnswerSheetActionsTypes.POST_CREATE_ANSWER_SHEET_APP: {
			return {
				...state,
				loading: true
			};
		}
		case CreateAnswerSheetActionsTypes.POST_CREATE_ANSWER_SHEET_APP_SUCCESS: {
			return {
				...state,
				answerSheet: action.data,
				create:{success:true, loading: false}
			};
		}	
		case CreateAnswerSheetActionsTypes.POST_CREATE_ANSWER_SHEET_APP_ERROR: {
			return {
				...state,
				create:{success:false, loading: false}
			};
		}				
		default:
			return state;
	}
};

export default createAswerSheetReducer;
