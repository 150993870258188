export interface IGradingsState {
    gradings:[],
    selectedGrading:{},
    loading: boolean,
    error: string,
    success: boolean,
    create: {
        loading: boolean,
        error: string,
        success: boolean,
    },
    delete: {
        loading: boolean,
        error: string,
        success: boolean,
    }
}

//#region -----------------CRUD Models----------------------------

export interface GetGradingModel {
    id: number
}

export interface GetGradingsModel {
    id: number
}

export interface CreateGradingModel {
    "examId": number,
    "completedForms": string[]
}

export interface UpdateGradingModel {
    "examId": number,
    "completedForms": []
}

export interface DeleteGradingModel {
    id: number
}

//#endregion

//#region // -----------------CRUD Types----------------------------

export enum GetGradingActionsTypes {
    GET_GRADING = 'GET_GRADING',
    GET_GRADING_SUCCESS = 'GET_GRADING_SUCCESS',
    GET_GRADING_ERROR = 'GET_GRADING_ERROR'
}

export enum GetGradingsActionsTypes {
    GET_GRADINGS = 'GET_GRADINGS',
    GET_GRADINGS_SUCCESS = 'GET_GRADINGS_SUCCESS',
    GET_GRADINGS_ERROR = 'GET_GRADINGS_ERROR'
}

export enum CreateGradingActionsTypes {
    POST_CREATE_GRADING = 'POST_CREATE_GRADING',
    POST_CREATE_GRADING_SUCCESS = 'POST_CREATE_GRADING_SUCCESS',
    POST_CREATE_GRADING_ERROR = 'POST_CREATE_GRADING_ERROR'
}

export enum UpdateGradingActionsTypes {
    PUT_UPDATE_GRADING = 'PUT_UPDATE_GRADING',
    PUT_UPDATE_GRADING_SUCCESS = 'PUT_UPDATE_GRADING_SUCCESS',
    PUT_UPDATE_GRADING_ERROR = 'PUT_UPDATE_GRADING_ERROR'
}

export enum DeleteGradingActionsTypes {
    DELETE_GRADING = 'DELETE_GRADING',
    DELETE_GRADING_SUCCESS = 'DELETE_GRADING_SUCCESS',
    DELETE_GRADING_ERROR = 'DELETE_GRADING_ERROR'
}

//#endregion

//#region // -----------------CRUD Requests----------------------------

//#region // -----------------Get Grading Request----------------------------

export interface GetGradingRequest {
    type: GetGradingActionsTypes.GET_GRADING
}

export interface GetGradingRequestSucces {
    type: GetGradingActionsTypes.GET_GRADING_SUCCESS,
    data: IGetGradingResult
    loading: boolean;
}

export interface GetGradingRequestError {
    type: GetGradingActionsTypes.GET_GRADING_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#region // -----------------Get Gradings Request----------------------------

export interface GetGradingsRequest {
    type: GetGradingsActionsTypes.GET_GRADINGS
}

export interface GetGradingsRequestSucces {
    type: GetGradingsActionsTypes.GET_GRADINGS_SUCCESS,
    data: IGetGradingsResult
    loading: boolean;
}

export interface GetGradingsRequestError {
    type: GetGradingsActionsTypes.GET_GRADINGS_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#region // -----------------Create Grading Request----------------------------

export interface CreateGradingRequest {
    type: CreateGradingActionsTypes.POST_CREATE_GRADING
}

export interface CreateGradingRequestSucces {
    type: CreateGradingActionsTypes.POST_CREATE_GRADING_SUCCESS,
    data: ICreateGradingResult
    loading: boolean
    success: boolean
}

export interface CreateGradingRequestError {
    type: CreateGradingActionsTypes.POST_CREATE_GRADING_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#region // -----------------Update Grading Request----------------------------

export interface UpdateGradingRequest {
    type: UpdateGradingActionsTypes.PUT_UPDATE_GRADING
}

export interface UpdateGradingRequestSucces {
    type: UpdateGradingActionsTypes.PUT_UPDATE_GRADING_SUCCESS,
    data: IUpdateGradingResult
    loading: boolean;
}

export interface UpdateGradingRequestError {
    type: UpdateGradingActionsTypes.PUT_UPDATE_GRADING_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#region // -----------------Delete Grading Request----------------------------

export interface DeleteGradingRequest {
    type: DeleteGradingActionsTypes.DELETE_GRADING
}

export interface DeleteGradingRequestSucces {
    type: DeleteGradingActionsTypes.DELETE_GRADING_SUCCESS,
    data: IDeleteGradingResult
    loading: boolean;
}

export interface DeleteGradingRequestError {
    type: DeleteGradingActionsTypes.DELETE_GRADING_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#endregion

//#region // -----------------CRUD Results----------------------------

export interface IGetGradingResult {
    data: {},
    success: boolean,
    error: number
}
export interface IGetGradingsResult {
    data: {
        gradings: []
    },
    success: boolean,
    error: number
}
export interface ICreateGradingResult {
    loading: boolean,
    error: string,
    success: boolean,
}
export interface IUpdateGradingResult {
    loading: boolean,
    error: string,
    success: boolean,
}
export interface IDeleteGradingResult {
    loading: boolean,
    error: string,
    success: boolean,
}

//#endregion

export type GradingActions = GetGradingRequest | GetGradingRequestSucces | GetGradingRequestError |
                        GetGradingsRequest | GetGradingsRequestSucces | GetGradingsRequestError |
                        CreateGradingRequest | CreateGradingRequestSucces | CreateGradingRequestError |
                        UpdateGradingRequest | UpdateGradingRequestSucces | UpdateGradingRequestError |
                        DeleteGradingRequest | DeleteGradingRequestSucces | DeleteGradingRequestError;