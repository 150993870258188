import React from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import i18n from '../../../locale/i18n';

import { PageTitle } from '../../../components/common/PageTitle/PageTitle';
import { useResponsive } from '../../../hooks/useResponsive';
import * as S from '../Legal.styles';

const href = window.location.href;
const noIndex = true;

const CopyrightNoticePage: React.FC = (props: any) => {
    const { isDesktop } = useResponsive();
    const { t } = useTranslation();
    
  
    const desktopLayout = (
      <>
      <S.H1 level={1}>Copyright Notice</S.H1>
      <S.ContentRight>Last Updated: 15 June 2023</S.ContentRight>

      <S.H5 level={2}>Copyrights</S.H5>
      <S.Paragraph>All of the content included on this web site including all of the text, graphics, photographs, graphs, sounds, data, images, audio, and video clips available on this web site are the property of Smallize Pty Ltd, also known as "OMR Quiz" or its licensors or content suppliers and is protected by Australian and international copyright laws. The compilation, collection, selection, arrangement, assembly, and coordination of all content available on this web site is the exclusive property of Smallize Pty Ltd and protected by Australian and international copyright laws. All of the content available on this web site may only be used by you as a shopping and information gathering resource. You may not copy, publish, distribute, create derivative works of, or commercially exploit the content of this web site, or use this web site for any other purpose.</S.Paragraph>

      <S.H5 level={2}>Trademarks</S.H5>
      <S.Paragraph>“OMR Quiz” is a trademark of Smallize Pty Ltd. All other graphics, logos, product, and service names used on this web site by Smallize Pty Ltd are the trademarks of Smallize Pty Ltd. Smallize trademarks may not be used in connection with any third-party products or services or in any manner that disparages or discredits Smallize. All other trademarks, brands, names, and logos appearing on this web site are the property of their respective owners.</S.Paragraph>
      </>
    );
  
    const mobileAndTabletLayout = (
      <Row gutter={[20, 24]}>
        <Col span={24}>
          
        </Col>
  
        <Col span={24}>
          
        </Col>
  
        <Col span={24}>
          
        </Col>
  
        <Col span={24}>
          
        </Col>
      </Row>
    );
  
    return (
      <>
        <PageTitle title={t('head:aboutUsTitle')} description={t('head:aboutUsDescription')} url={href} noIndex={noIndex}></PageTitle>
        {isDesktop ? desktopLayout : mobileAndTabletLayout}
      </>
    );
  };

export default CopyrightNoticePage