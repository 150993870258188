import React from 'react';
import { DropdownCollapse } from '../../../../Header.styles';
import { useTranslation } from 'react-i18next';
import { LanguagePicker } from '../LanguagePicker/LanguagePicker';
import { Button } from '../../../../../common/buttons/Button/Button';
import { useTypedSelector } from '../../../../../../hooks/useTypedSelector';
import * as S from './SettingsOverlay.styles';

export const SettingsOverlay: React.FC = ({ ...props }) => {
  const { t } = useTranslation();

  const { isPWASupported, event } = useTypedSelector((state) => state.pwa);

  return (
    <S.SettingsOverlayMenu {...props}>
      <DropdownCollapse bordered={false} expandIconPosition="end" ghost defaultActiveKey="themePicker">
        {/* <DropdownCollapse.Panel header={t('header:changeLanguage')} key="languagePicker"> */}
          <LanguagePicker />
        {/* </DropdownCollapse.Panel> */}
      </DropdownCollapse>
    </S.SettingsOverlayMenu>
  );
};
