/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../../HomeLayout/components/MKBox";
import MKTypography from "../../../../HomeLayout/components/MKTypography";

function DocsCard({ description, title, item, href }) {
  return (
    <Card sx={{ mt: 3 }}>
      <Grid container>
        <Grid sx={{ mt: -6, ml: 6 }}>
          
        </Grid>
        <Grid item xs={11} md={11} lg={11} sx={{ my: "auto" }}>
          <MKBox pt={{ xs: 1, lg: 2.5 }} pb={2.5} pr={4} pl={{ xs: 4, lg: 1 }} lineHeight={1}>
          
            <MKTypography variant="h2" style={{fontSize: 24}}>
            {title}
           </MKTypography>
            <MKTypography variant="body2" color="text" pt={2}>
              {description}
              
            </MKTypography>
            <MKTypography variant="body2" color="text" pt={1}>
            <span><a href= {href}>{item}</a></span>
            </MKTypography>
            
          </MKBox>
        </Grid>
      </Grid>
    </Card>
  );
}

// Typechecking props for the DocsCard
DocsCard.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  position: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string,
  }),
  description: PropTypes.string.isRequired,
};

export default DocsCard;
