// -------------------   API Used https://developers.themoviedb.org/   ----------------------

export enum URLs {
    BASE_URL = "https://api.omrquiz.com/",
    //BASE_URL = "https://api.qa.omrquiz.com/",
    //BASE_URL = "https://localhost:44326/",

    // -----------------------Auth APIs------------------------
    POST_LOGIN_ENDPOINT = 'api/Auth/Login',
    POST_SIGN_UP_ENDPOINT = 'api/Auth/Register',
    GET_DEFAULT_REGISTER_ENDPOINT = 'api/Auth/DefaultRegister',

    // -----------------------Classes APIs------------------------
    GET_CLASS_ENDPOINT = 'api/Class/GetClass',
    GET_CLASSES_ENDPOINT = 'api/Class/GetClasses',
    CREATE_CLASS_ENDPOINT = 'api/Class/CreateClass',
    UPDATE_CLASS_ENDPOINT = 'api/Class/UpdateClass',
    DELETE_CLASS_ENDPOINT = 'api/Class/DeleteClass',

    // -----------------------Students APIs------------------------
    GET_STUDENT_ENDPOINT = 'api/Student/GetStudent',
    CREATE_STUDENT_ENDPOINT = 'api/Student/CreateStudent',
    UPDATE_STUDENT_ENDPOINT = 'api/Student/UpdateStudent',
    DELETE_STUDENT_ENDPOINT = 'api/Student/DeleteStudent',

    // -----------------------Grading Scales APIs------------------------
    GET_GRADING_SCALE_ENDPOINT = 'api/GradingScale/GetGradingScale',
    GET_GRADING_SCALES_ENDPOINT = 'api/GradingScale/GetGradingScales',
    CREATE_GRADING_SCALE_ENDPOINT = 'api/GradingScale/CreateGradingScale',
    UPDATE_GRADING_SCALE_ENDPOINT = 'api/GradingScale/UpdateGradingScale',
    DELETE_GRADING_SCALE_ENDPOINT = 'api/GradingScale/DeleteGradingScale',

    // -----------------------Answer Sheets APIs------------------------
    GET_ANSWER_SHEET_ENDPOINT = 'api/AnswerSheetForm/GetAnswerSheetForm',
    GET_ANSWER_SHEETS_ENDPOINT = 'api/AnswerSheetForm/GetAnswerSheetForms',
    CREATE_ANSWER_SHEET_ENDPOINT = 'api/AnswerSheetForm/CreateAnswerSheetForm',
    UPDATE_ANSWER_SHEET_ENDPOINT = 'api/AnswerSheetForm/UpdateAnswerSheetForm',
    DELETE_ANSWER_SHEET_ENDPOINT = 'api/AnswerSheetForm/DeleteAnswerSheetForm',

    // -----------------------Assessments APIs------------------------
    GET_ASSESSMENT_ENDPOINT = 'api/Assessment/GetAssessment',
    GET_ASSESSMENTS_ENDPOINT = 'api/Assessment/GetAssessments',
    CREATE_ASSESSMENT_ENDPOINT = 'api/Assessment/CreateAssessment',
    UPDATE_ASSESSMENT_ENDPOINT = 'api/Assessment/UpdateAssessment',
    DELETE_ASSESSMENT_ENDPOINT = 'api/Assessment/DeleteAssessment',

    // -----------------------Gradings APIs------------------------
    GET_GRADING_ENDPOINT = 'api/Grading/GetGrading',
    GET_GRADINGS_ENDPOINT = 'api/Grading/GetGradings',
    CREATE_GRADING_ENDPOINT = 'api/Grading/CreateGrading',
    UPDATE_GRADING_ENDPOINT = 'api/Grading/UpdateGrading',
    DELETE_GRADING_ENDPOINT = 'api/Grading/DeleteGrading',

    // -----------------------Batches APIs------------------------
    CREATE_BATCH_RECOGNITION = 'api/Batch/BatchRecognition',

    // -----------------------Other apps-------------------------------
    CREATE_ANSWER_SHEET_APP_ENDPOINT = 'api/CreateAnswerSheet/CreateAnswerSheet',
    SCAN_ANSWER_SHEET_APP_ENDPOINT = 'api/ScanAnswerSheet/ScanAnswerSheet',
    CREATE_SURVEY_APP_ENDPOINT = 'api/CreateSurvey/CreateSurvey',
    SCAN_SURVEY_APP_ENDPOINT = 'api/ScanSurvey/ScanSurvey',
}

