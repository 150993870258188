export interface ISignUpState {
    loading: boolean,
    error: string,
    success: boolean,
}

export interface PostSignUpModel {
    email: string,
    password: string
}

export enum PostSignUpActionsTypes {
    POST_SIGN_UP = 'POST_SIGN_UP',
    POST_SIGN_UP_SUCCESS = 'POST_SIGN_UP_SUCCESS',
    POST_SIGN_UP_ERROR = 'POST_SIGN_UP_ERROR'
}

export interface ISignUpResult {
    success: boolean,
    error: number
}

export interface PostSignUpRequest {
    type: PostSignUpActionsTypes.POST_SIGN_UP,
}

export interface PostSignUpRequestSucces {
    type: PostSignUpActionsTypes.POST_SIGN_UP_SUCCESS,
    data: ISignUpResult
    loading: boolean;
}

export interface PostSignUpRequestError {
    type: PostSignUpActionsTypes.POST_SIGN_UP_ERROR,
    error:string,
    loading:boolean
}

export type SignUpActions = PostSignUpRequest | PostSignUpRequestSucces | PostSignUpRequestError;