import styled from 'styled-components';
import { Layout, Typography } from 'antd';
import { FONT_SIZE, media } from '../../../styles/themes/constants';
import { Link } from 'react-router-dom';

export const LayoutMaster = styled(Layout)`
  height: 100vh;
`;

export const LayoutMain = styled(Layout)`
  @media only screen and ${media.md} {
    margin-left: 80px;
  }

  @media only screen and ${media.xl} {
    margin-left: unset;
  }
`;

export const Paragraph = styled(Typography.Paragraph)`
  &.ant-typography {
    margin-bottom:10;
    font-size: ${FONT_SIZE.xs};
  }
`;

export const TextLink = styled(Link)`
  &.ant-typography {
    margin-bottom:0;
    padding-left:20px;
    padding-bottom:20px;
    font-size: ${FONT_SIZE.xxs};
  }
`;