export interface IStudentState {
    students:[],
    selectedStudent: [],
    selectedId: number,
    loading: boolean,
    error: string,
    success: boolean,
    create: {
        loading: boolean,
        error: string,
        success: boolean,
    },
    delete: {
        loading: boolean,
        error: string,
        success: boolean,
    }
}

//#region -----------------CRUD Models----------------------------

export interface GetClassModel {
    id: number
}

export interface GetStudentModel {
    id: number
}

export interface CreateStudentModel {
    firstName: string,
    lastName: string,
    classStudentId: string,
    classId: number
}

export interface UpdateStudentModel {
    firstName: string,
    lastName: string,
    classStudentId: string,
    classId: number
}

export interface DeleteStudentModel {
    studentId: number
}

//#endregion

//#region // -----------------CRUD Types----------------------------

export enum GetClassActionsTypes {
    GET_CLASS = 'GET_CLASS',
    GET_CLASS_SUCCESS = 'GET_CLASS_SUCCESS',
    GET_CLASS_ERROR = 'GET_CLASS_ERROR'
}

export enum GetStudentActionsTypes {
    GET_STUDENT = 'GET_STUDENT',
    GET_STUDENT_SUCCESS = 'GET_STUDENT_SUCCESS',
    GET_STUDENT_ERROR = 'GET_STUDENT_ERROR'
}

export enum CreateStudentActionsTypes {
    POST_CREATE_STUDENT = 'POST_CREATE_STUDENT',
    POST_CREATE_STUDENT_SUCCESS = 'POST_CREATE_STUDENT_SUCCESS',
    POST_CREATE_STUDENT_ERROR = 'POST_CREATE_STUDENT_ERROR'
}

export enum UpdateStudentActionsTypes {
    PUT_UPDATE_STUDENT = 'PUT_UPDATE_STUDENT',
    PUT_UPDATE_STUDENT_SUCCESS = 'PUT_UPDATE_STUDENT_SUCCESS',
    PUT_UPDATE_STUDENT_ERROR = 'PUT_UPDATE_STUDENT_ERROR'
}

export enum DeleteStudentActionsTypes {
    DELETE_STUDENT = 'DELETE_STUDENT',
    DELETE_STUDENT_SUCCESS = 'DELETE_STUDENT_SUCCESS',
    DELETE_STUDENT_ERROR = 'DELETE_STUDENT_ERROR'
}

//#endregion

//#region // -----------------CRUD Requests----------------------------

//#region // -----------------Get Class Request----------------------------

export interface GetClassRequest {
    type: GetClassActionsTypes.GET_CLASS
}

export interface GetClassRequestSucces {
    type: GetClassActionsTypes.GET_CLASS_SUCCESS,
    data: {}
    id: number
    loading: boolean
}

export interface GetClassRequestError {
    type: GetClassActionsTypes.GET_CLASS_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#region // -----------------Get Student Request----------------------------

export interface GetStudentRequest {
    type: GetStudentActionsTypes.GET_STUDENT
}

export interface GetStudentRequestSucces {
    type: GetStudentActionsTypes.GET_STUDENT_SUCCESS,
    data: {},
    id: number,
    loading: boolean
}

export interface GetStudentRequestError {
    type: GetStudentActionsTypes.GET_STUDENT_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#region // -----------------Create Student Request----------------------------

export interface CreateStudentRequest {
    type: CreateStudentActionsTypes.POST_CREATE_STUDENT
}

export interface CreateStudentRequestSucces {
    type: CreateStudentActionsTypes.POST_CREATE_STUDENT_SUCCESS,
    data: ICreateStudentResult
    loading: boolean
    success: boolean
}

export interface CreateStudentRequestError {
    type: CreateStudentActionsTypes.POST_CREATE_STUDENT_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#region // -----------------Update Student Request----------------------------

export interface UpdateStudentRequest {
    type: UpdateStudentActionsTypes.PUT_UPDATE_STUDENT
}

export interface UpdateStudentRequestSucces {
    type: UpdateStudentActionsTypes.PUT_UPDATE_STUDENT_SUCCESS,
    data: IUpdateStudentResult
    loading: boolean;
}

export interface UpdateStudentRequestError {
    type: UpdateStudentActionsTypes.PUT_UPDATE_STUDENT_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#region // -----------------Delete Student Request----------------------------

export interface DeleteStudentRequest {
    type: DeleteStudentActionsTypes.DELETE_STUDENT
}

export interface DeleteStudentRequestSucces {
    type: DeleteStudentActionsTypes.DELETE_STUDENT_SUCCESS,
    data: IDeleteStudentResult
    loading: boolean
    success: boolean
}

export interface DeleteStudentRequestError {
    type: DeleteStudentActionsTypes.DELETE_STUDENT_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#endregion

//#region // -----------------CRUD Results----------------------------

export interface IGetClassResult {
    data: {
        id: number,
        students: []
    },
    success: boolean,
    error: number
}

export interface IGetStudentResult {
    data: {
        id: number,
        studentExamItems: []
    },
    success: boolean,
    error: number
}
export interface ICreateStudentResult {
    loading: boolean,
    error: string,
    success: boolean
}
export interface IUpdateStudentResult {
    loading: boolean,
    error: string,
    success: boolean,
}
export interface IDeleteStudentResult {
    loading: boolean,
    error: string,
    success: boolean,
}

//#endregion

export type StudentActions = GetClassRequest | GetClassRequestSucces | GetClassRequestError |
                        GetStudentRequest | GetStudentRequestSucces | GetStudentRequestError |
                        CreateStudentRequest | CreateStudentRequestSucces | CreateStudentRequestError |
                        UpdateStudentRequest | UpdateStudentRequestSucces | UpdateStudentRequestError |
                        DeleteStudentRequest | DeleteStudentRequestSucces | DeleteStudentRequestError;