export interface ICreateAnswerSheetState {
    answerSheet: [],
    answerSheetId: number,
    loading: boolean,
    error: string,
    success: boolean,
    create: {
        loading: boolean,
        error: string,
        success: boolean,
    },
    delete: {
        loading: boolean,
        error: string,
        success: boolean,
    }
}

//#region -----------------CRUD Models----------------------------

export interface CreateAnswerSheetModel {
    "name": string,
    "questionsNumber": number,
    "numberOfBubbles": number,
    "answerKeys": string,
    "colorOfBubbles": string,
    "paperSize": string,
    //"createForm": true
}

//#endregion

//#region // -----------------CRUD Types----------------------------

export enum CreateAnswerSheetActionsTypes {
    POST_CREATE_ANSWER_SHEET_APP = 'POST_CREATE_ANSWER_SHEET_APP',
    POST_CREATE_ANSWER_SHEET_APP_SUCCESS = 'POST_CREATE_ANSWER_SHEET_APP_SUCCESS',
    POST_CREATE_ANSWER_SHEET_APP_ERROR = 'POST_CREATE_ANSWER_SHEET_APP_ERROR'
}

//#endregion

//#region // -----------------CRUD Requests----------------------------

//#region // -----------------Create Answer Sheet Request----------------------------

export interface CreateAnswerSheetRequest {
    type: CreateAnswerSheetActionsTypes.POST_CREATE_ANSWER_SHEET_APP
}

export interface CreateAnswerSheetRequestSucces {
    type: CreateAnswerSheetActionsTypes.POST_CREATE_ANSWER_SHEET_APP_SUCCESS,
    data: string
    loading: boolean
    success: boolean
}

export interface CreateAnswerSheetRequestError {
    type: CreateAnswerSheetActionsTypes.POST_CREATE_ANSWER_SHEET_APP_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#endregion

//#region // -----------------CRUD Results----------------------------

export interface ICreateAnswerSheetResult {
    data: string,
    loading: boolean,
    error: string,
    success: boolean,
}

//#endregion

export type CreateAnswerSheetActions = CreateAnswerSheetRequest | CreateAnswerSheetRequestSucces | CreateAnswerSheetRequestError;