import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { history } from "../store";

import languageDE from "./translations/de/translate";
import languageEL from "./translations/el/translate";
import languageEN from "./translations/en/translate";
import languageES from "./translations/es/translate";
import languageFI from "./translations/fi/translate";
import languageFR from "./translations/fr/translate";
import languageHI from "./translations/hi/translate";
import languageID from "./translations/id/translate";
import languageIT from "./translations/it/translate";
import languageJA from "./translations/ja/translate";
import languageKK from "./translations/kk/translate";
import languageKO from "./translations/ko/translate";
import languageMS from "./translations/ms/translate";
import languagePL from "./translations/pl/translate";
import languagePT from "./translations/pt/translate";
import languageRU from "./translations/ru/translate";
import languageSV from "./translations/sv/translate";
import languageTR from "./translations/tr/translate";
import languageUK from "./translations/uk/translate";
import languageZH from "./translations/zh/translate";

// i18n.on("languageChanged", function (lng) {
//   let finish = false;
//   const pathName = window.location.pathname;
//   const newUrl = '';
//   // if (!pathName.includes("/" + lng)) {
//   //   // if(lng == "en" && !window.location.pathname.includes("/ru")){
//   //   //   finish = true;
//   //   // }
//   //   for (var item in i18n.options.otherLanguages) {
//   //     if (
//   //       pathName.includes(
//   //         "/" + i18n.options.otherLanguages[item]
//   //       ) &&
//   //       i18n.options.otherLanguages[item] != lng
//   //     ) {
//   //       if (!lng == "en") {
//   //         newUrl = pathName.replace(
//   //           "/" + i18n.options.otherLanguages[item],
//   //           lng
//   //         );
//   //       }
//   //       else {
//   //         newUrl = pathName.replace(
//   //           "/" + i18n.options.otherLanguages[item],
//   //           lng
//   //         );
//   //       }

//   //       localStorage.setItem('lng', lng);
//   //       history.push(newUrl);
//   //       finish = true;
//   //       break;
//   //     }
//   //   }
//   //   if (!finish) {
//   //     localStorage.setItem('lng', lng);
//   //     if(!lng == 'en'){
//   //       history.push(
//   //         "/" + lng + pathName
//   //       );
//   //     }
//   //     else{
//   //       history.push(
//   //         pathName
//   //       );
//   //     }
//   //   }
//   // }
// });

// i18n.on("languageChanged", function (lng) {
//   let finish = false;
//   console.log("lng", lng);
//   if(window.location.pathname.includes("/en")){
//     const newUrl = window.location.pathname.replace(
//       "/en",
//       ""
//     );
//     console.log("newUrl ::::::::::::::",newUrl)
//     window.history.replaceState(null, "", newUrl);
//     finish = true;
//   }
//   if (!window.location.pathname.includes("/" + lng) ) {
//     // if(lng == "en" && !window.location.pathname.includes("/ru")){
//     //   finish = true;
//     // }

//     if(window.location.pathname.includes("/" + lng)){
//       finish = true;
//     }
//     if ( window.location.pathname.includes("/ru") && lng == "uk" ) {
//       const newUrl = window.location.pathname.replace(
//         "/ru",
//         ""
//       );
//       console.log("new",newUrl)
//       window.history.replaceState(null, "", newUrl);
//       finish = true;
//     }
//     if (!finish) {
//       window.history.replaceState(
//         null,
//         "",
//         "/" + lng + window.location.pathname
//       );
//     }
//   }
// });

export const allLanguages = ["de", "el", "en", "es", "fi", "fr", "hi", "id", "it", "ja", "kk", "ko", "ms", "pl", "pt", "ru", "sv", "tr", "uk", "zh"];

i18n.on("languageChanged", function (lng) {
  let finish = false;
  const pathName = window.location.pathname;
  let newUrl = '';
  if (!pathName.includes("/" + lng) ) {
    for (var item in i18n.options.otherLanguages){
      if (
        pathName.includes(
                "/" + i18n.options.otherLanguages[item]
              ) &&
              i18n.options.otherLanguages[item] != lng
            ) {
              if (lng != "en") {
                newUrl = pathName.replace(
                  "/" + i18n.options.otherLanguages[item],
                  lng
                );
              }
              else {
                newUrl = pathName.replace(
                  "/" + i18n.options.otherLanguages[item],
                  ""
                );
              }
              localStorage.setItem('lng', lng);
              window.history.replaceState(null, "", newUrl);
              window.location.replace(newUrl);
        finish = true;
        break;
    }}
    if(lng == "en"){
      finish = true;
    }
    
    if (!finish) {
      // window.history.replaceState(
      //   null,
      //   "",
      //   "/" + lng + window.location.pathname
      // );
      window.location.replace("/" + lng + pathName);
    }
  }
  if (pathName.includes("/en") ) {
    const newUrl = window.location.pathname.replace(
      "/en",
      ""
    );
    window.history.replaceState(null, "", newUrl);
    window.location.replace(newUrl);
    finish = true;
    localStorage.setItem('newUrl', newUrl);
    localStorage.setItem('pathName', pathName);
  }
});

i18n
  //.use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      de: languageDE,
      el: languageEL,
      en: languageEN,
      es: languageES,
      fi: languageFI,
      fr: languageFR,
      hi: languageHI,
      id: languageID,
      it: languageIT,
      ja: languageJA,
      kk: languageKK,
      ko: languageKO,
      ms: languageMS,
      pl: languagePL,
      pt: languagePT,
      ru: languageRU,
      sv: languageSV,
      tr: languageTR,
      uk: languageUK,
      zh: languageZH
    },
    otherLanguages: ["de", "el", "en", "es", "fi", "fr", "hi", "id", "it", "ja", "kk", "ko", "ms", "pl", "pt", "ru", "sv", "tr", "uk", "zh"],
    react: {
      wait: true,
      useSuspense: true,
      bindI18n: "languageChanged loaded",
      bindStore: "added removed",
      nsMode: "default",
    },
    fallbackLng: ["en"],
    whitelist: ["de", "el", "en", "es", "fi", "fr", "hi", "id", "it", "ja", "kk", "ko", "ms", "pl", "pt", "ru", "sv", "tr", "uk", "zh"],
    detection: {
      order: ["path", 'localStorage'],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: ".",
    },
  });

export default i18n;
