// import {applyMiddleware} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {rootReducer} from './reducers/rootReducer';

import {handler as userSaga} from './reducers/user/saga';
import {handler as signUpSaga} from './reducers/sign_up/saga';
import {handler as classesSaga} from './reducers/classes/saga';
import {handler as studentsSaga} from './reducers/students/saga';
import {handler as gradingScalesSaga} from './reducers/grading_scales/saga';
import {handler as answerSheetsSaga} from './reducers/answer_sheets/saga';
import {handler as assessmentsSaga} from './reducers/assessments/saga';
import {handler as gradingsSaga} from './reducers/grading/saga';

//--------------------Other apps-------------------------------------------
import {handler as createAnswerSheetSaga} from './reducers/create_answer_sheet/saga';
import {handler as scanAnswerSheetSaga} from './reducers/scan_answer_sheet/saga';
import {handler as createSurveySaga} from './reducers/create_survey/saga';
import {handler as scanSurveySaga} from './reducers/scan_survey/saga';


import { configureStore } from '@reduxjs/toolkit';

import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
        reducer: rootReducer,   
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
        devTools: process.env.NODE_ENV !== 'production'});
        
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;

sagaMiddleware.run(userSaga);
sagaMiddleware.run(signUpSaga);
sagaMiddleware.run(classesSaga);
sagaMiddleware.run(studentsSaga);
sagaMiddleware.run(gradingScalesSaga);
sagaMiddleware.run(answerSheetsSaga);
sagaMiddleware.run(assessmentsSaga);
sagaMiddleware.run(gradingsSaga);

sagaMiddleware.run(createAnswerSheetSaga);
sagaMiddleware.run(scanAnswerSheetSaga);
sagaMiddleware.run(createSurveySaga);
sagaMiddleware.run(scanSurveySaga);
