import { UserModel } from "../../../domain/UserModel";

export interface IUserState {
    isLoggedIn: boolean;
    user: UserModel | null,
	login: {
        loading: boolean,
        error: string,
        success: boolean,
    },
    defaultRegister:{
        loading: boolean,
        error: string,
        success: boolean,
    }
}

export interface IUser {
 
}

export interface PostLoginModel {
   email: string,
   password: string
}

export enum PostLoginActionsTypes {
    POST_LOGIN = 'POST_LOGIN',
    POST_LOGIN_SUCCESS = 'POST_LOGIN_SUCCESS',
    POST_LOGIN_ERROR = 'POST_LOGIN_ERROR'
}

export enum DefaultRegisterActionsTypes {
    DEFAULT_REGISTER = 'DEFAULT_REGISTER',
    DEFAULT_REGISTER_SUCCESS = 'DEFAULT_REGISTER_SUCCESS',
    DEFAULT_REGISTER_ERROR = 'DEFAULT_REGISTER_ERROR'
}

export interface ILoginResult {
    data: {
        token: string,
        refreshToken: string
    }
    success: boolean,
    error: number
}

export interface IDefaultRegisterResult {
    data: string,
    success: boolean,
    error: number
}

export interface PostLoginRequest {
    type: PostLoginActionsTypes.POST_LOGIN,
}

export interface PostLoginRequestSucces {
    type: PostLoginActionsTypes.POST_LOGIN_SUCCESS,
    data: ILoginResult
    loading: boolean;
}

export interface PostLoginRequestError {
    type: PostLoginActionsTypes.POST_LOGIN_ERROR,
    error:string,
    loading:boolean
}

export interface DefaultRegisterRequest {
    type: DefaultRegisterActionsTypes.DEFAULT_REGISTER,
}

export interface DefaultRegisterRequestSucces {
    type: DefaultRegisterActionsTypes.DEFAULT_REGISTER_SUCCESS,
    data: IDefaultRegisterResult
    loading: boolean;
}

export interface DefaultRegisterRequestError {
    type: DefaultRegisterActionsTypes.DEFAULT_REGISTER_ERROR,
    error:string,
    loading:boolean
}

export type UserActions = PostLoginRequest | PostLoginRequestSucces | PostLoginRequestError |
                DefaultRegisterRequest | DefaultRegisterRequestSucces | DefaultRegisterRequestError;