export interface IScanAnswerSheetState {
    recognitionResult: string,
    recognitionResultId: number,
    loading: boolean,
    error: string,
    success: boolean,
    create: {
        loading: boolean,
        error: string,
        success: boolean,
    },
}

//#region -----------------CRUD Models----------------------------

export interface ScanAnswerSheetModel {
    "name": string,
    "questionsNumber": number,
    "numberOfBubbles": number,
    "answerKeys": string,
    "paperSize": string,
    "image": string
}

//#endregion

//#region // -----------------CRUD Types----------------------------

export enum ScanAnswerSheetActionsTypes {
    POST_SCAN_ANSWER_SHEET_APP = 'POST_SCAN_ANSWER_SHEET_APP',
    POST_SCAN_ANSWER_SHEET_APP_SUCCESS = 'POST_SCAN_ANSWER_SHEET_APP_SUCCESS',
    POST_SCAN_ANSWER_SHEET_APP_ERROR = 'POST_SCAN_ANSWER_SHEET_APP_ERROR'
}

//#endregion

//#region // -----------------CRUD Requests----------------------------

//#region // -----------------Scan Answer Sheet Request----------------------------

export interface ScanAnswerSheetRequest {
    type: ScanAnswerSheetActionsTypes.POST_SCAN_ANSWER_SHEET_APP
}

export interface ScanAnswerSheetRequestSucces {
    type: ScanAnswerSheetActionsTypes.POST_SCAN_ANSWER_SHEET_APP_SUCCESS,
    data: string
    loading: boolean
    success: boolean
}

export interface ScanAnswerSheetRequestError {
    type: ScanAnswerSheetActionsTypes.POST_SCAN_ANSWER_SHEET_APP_ERROR,
    error:string,
    loading:boolean
}

//#endregion

//#endregion

//#region // -----------------CRUD Results----------------------------

export interface IScanAnswerSheetResult {
    data: string,
    loading: boolean,
    error: string,
    success: boolean,
}

//#endregion

export type ScanAnswerSheetActions = ScanAnswerSheetRequest | ScanAnswerSheetRequestSucces | ScanAnswerSheetRequestError;