/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import i18n from '../../../../../../../locale/i18n';

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "../../../../examples/Cards/CounterCards/DefaultCounterCard";

// Images
import coinbase from "../../../../../../../assets/images/home-images/gray-logos/logo-coinbase.svg";
import nasa from "../../../../../../../assets/images/home-images/gray-logos/logo-nasa.svg";
import netflix from "../../../../../../../assets/images/home-images/gray-logos/logo-netflix.svg";
import pinterest from "../../../../../../../assets/images/home-images/gray-logos/logo-pinterest.svg";
import spotify from "../../../../../../../assets/images/home-images/gray-logos/logo-spotify.svg";
import vodafone from "../../../../../../../assets/images/home-images/gray-logos/logo-vodafone.svg";

function Featuring() {
  return (
    <MKBox component="section" pt={3} pb={8}>
      <Container>
        <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              icon="manage_accounts"
              title={i18n.t('featuring:title1')}
              description={i18n.t('featuring:description1')}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              icon="quiz"
              title={i18n.t('featuring:title2')}
              description={i18n.t('featuring:description2')}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              icon="grading"
              title={i18n.t('featuring:title3')}
              description={i18n.t('featuring:description3')}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Featuring;
