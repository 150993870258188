import React from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import i18n from '../../../locale/i18n';

import { PageTitle } from '../../../components/common/PageTitle/PageTitle';
import { useResponsive } from '../../../hooks/useResponsive';
import * as S from '../Legal.styles';

const href = window.location.href;
const noIndex = true;

const FileRetentionPolicyPage: React.FC = (props: any) => {
    const { isDesktop } = useResponsive();
    const { t } = useTranslation();
  
    const desktopLayout = (
      <>
      <S.H1 level={1}>File Retention Policy</S.H1>
      <S.ContentRight>Last Updated: 15 June 2023</S.ContentRight>

      <S.H5 level={2}>File Retention Policy</S.H5>
      <S.Paragraph>OMR Quiz App allows customers to upload files for conversation or other document operations. This policy outlines how we treat retention of those files.</S.Paragraph>
      <S.Paragraph>All files uploaded to the website are retained for a maximum of 24 hours before being deleted automatically thereafter. These files are not accessible to any person other than the person who uploads them.</S.Paragraph>
      <S.Paragraph>*n.b. The above file retention policy outlines how we usually deal with the retention of files for accounts. This policy does not affect the ultimate right of OMR Quiz to request an OMR Quiz account holder to remove any or all content as outlined in section 5.2 of our Terms of Service.*</S.Paragraph>
      </>
    );
  
    const mobileAndTabletLayout = (
      <Row gutter={[20, 24]}>
        <Col span={24}>
          
        </Col>
  
        <Col span={24}>
          
        </Col>
  
        <Col span={24}>
          
        </Col>
  
        <Col span={24}>
          
        </Col>
      </Row>
    );
  
    return (
      <>
        <PageTitle title={t('head:aboutUsTitle')} description={t('head:aboutUsDescription')} url={href} noIndex={noIndex}></PageTitle>
        {isDesktop ? desktopLayout : mobileAndTabletLayout}
      </>
    );
  };

export default FileRetentionPolicyPage