import { takeLatest, put, call, select } from 'redux-saga/effects';
import { apiRequest } from '../../apiRequest';
import { URLs } from '../../urls';
import {
	PostSignUpActionsTypes,
	ISignUpResult,
	PostSignUpRequestSucces,
	PostSignUpRequestError
} from './types';

function* sendPostSignUpRequest(action: any) {
	try {
		// API call
		const response : ISignUpResult = yield call(apiRequest, {
			endpoint: URLs.POST_SIGN_UP_ENDPOINT,
			method: 'POST',
			body: action.model
		});

		if (response.success == true) {
			let putData: PostSignUpRequestSucces = {
				type: PostSignUpActionsTypes.POST_SIGN_UP_SUCCESS,
				data: response,
				loading: false,
			};
			yield put<PostSignUpRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: PostSignUpRequestError = {
				type: PostSignUpActionsTypes.POST_SIGN_UP_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<PostSignUpRequestError>(putData);
		}

	} catch (e: any) {
		let putData: PostSignUpRequestError = {
			type: PostSignUpActionsTypes.POST_SIGN_UP_ERROR,
			error: e,
			loading: false
		};
		yield put<PostSignUpRequestError>(putData);
	}
}


export function* handler() {
	yield takeLatest(PostSignUpActionsTypes.POST_SIGN_UP, sendPostSignUpRequest);
}

