import React from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { PageTitle } from '../../../components/common/PageTitle/PageTitle';
import { useResponsive } from '../../../hooks/useResponsive';
import * as S from '../Legal.styles';
import i18n from '../../../locale/i18n';
import usaFlag from "../../../assets/icons/flags/united_states.svg";
import australiaFlag from "../../../assets/icons/flags/australia.svg";
import englandFlag from "../../../assets/icons/flags/united kingdom.svg";

const href = window.location.href;
const noIndex = true;

const ContactPage: React.FC = (props: any) => {
  const { isDesktop } = useResponsive();
  const { t } = useTranslation();

  const desktopLayout = (
    <>
      <S.H1 level={1}>Contact</S.H1>

      <S.H5 level={2}>Contact Sales Team</S.H5>
      <S.Paragraph>Please don’t contact the sales teams with support questions. Instead, post questions in the
        <a href="https://forum.omrquiz.com/" rel="alternate">&nbsp;Support Forums</a>
        .</S.Paragraph>
      <table style={{ textAlign: 'left', width: '100%', marginTop: '4vh', marginBottom: '5vh', }}>
        <tr>
          <th><S.Paragraph>America</S.Paragraph></th>
          <th><S.Paragraph>Europe</S.Paragraph></th>
          <th><S.Paragraph>Oceania</S.Paragraph></th>
        </tr>
        <tr>
          <td><S.Paragraph style={{display:'inline-table'}}><img style={{height:24, width:24}} src={usaFlag}/>USA</S.Paragraph></td>
          <td><S.Paragraph style={{display:'inline-table'}}><img style={{height:24, width:24}} src={englandFlag}/>UK</S.Paragraph></td>
          <td><S.Paragraph style={{display:'inline-table'}}><img style={{height:24, width:24}} src={australiaFlag}/>Australia</S.Paragraph></td>
        </tr>
        <tr>
          <td><S.Paragraph><a href="mailto:sales@omrquiz.com" rel="alternate">sales@omrquiz.com</a></S.Paragraph></td>
          <td><S.Paragraph><a href="mailto:sales@omrquiz.com" rel="alternate">sales@omrquiz.com</a></S.Paragraph></td>
          <td><S.Paragraph><a href="mailto:sales@omrquiz.com" rel="alternate">sales@omrquiz.com</a></S.Paragraph></td>
        </tr>
      </table>

      <S.H5 level={2}>Mailing Address:</S.H5>
      <S.Paragraph>Smallize Pty Ltd, Suite 163, 79 Longueville Road, Lane Cove, NSW, 2066, Australia.</S.Paragraph>
    </>
  );

  const mobileAndTabletLayout = (
    <Row gutter={[20, 24]}>
      <Col span={24}>

      </Col>

      <Col span={24}>

      </Col>

      <Col span={24}>

      </Col>

      <Col span={24}>

      </Col>
    </Row>
  );

  return (
    <>
      <PageTitle title={t('head:aboutUsTitle')} description={t('head:aboutUsDescription')} url={href} noIndex={noIndex}></PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default ContactPage