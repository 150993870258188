import {
	GetGradingActionsTypes, GetGradingsActionsTypes, CreateGradingActionsTypes, UpdateGradingActionsTypes, DeleteGradingActionsTypes,
	IGradingsState,
	GradingActions,
} from './types';

const initialState : IGradingsState = {
	gradings:[],
    selectedGrading:{},
    loading: false,
    error: '',
    success: false,
	create: {
        loading: false,
        error: '',
        success: false,
    },
	delete: {
        loading: false,
        error: '',
        success: false,
    }
};

const gradingsReducer = (state: IGradingsState = initialState, action: GradingActions) => {
	switch (action.type) {
		
		// ----------------------Get Grading Actions-----------------------
		case GetGradingActionsTypes.GET_GRADING: {
			return {
				...state,
				loading: true
			};
		}
		case GetGradingActionsTypes.GET_GRADING_SUCCESS: {
			const { loading, data} = action;
			return {
				...state,
				data: data.data,
				loading, success: true
			};
		}
		case GetGradingActionsTypes.GET_GRADING_ERROR: {
			const { error, loading } = action;
			return {
				...state,
				loading: false, success: false, error: error
			};
		}

		// ----------------------Get Gradings Actions-----------------------
		case GetGradingsActionsTypes.GET_GRADINGS: {
			return {
				...state,
				loading: true
			};
		}
		case GetGradingsActionsTypes.GET_GRADINGS_SUCCESS: {
			const { loading, data} = action;
			return {
				...state,
				loading, 
				success: true,
				gradings: data.data.gradings
			};
		}	
		case GetGradingsActionsTypes.GET_GRADINGS_ERROR: {
			const { error, loading } = action;
			return {
				...state,
				loading: false, success: false, error: error
			};
		}				

		// ----------------------Create Grading Actions-----------------------
		case CreateGradingActionsTypes.POST_CREATE_GRADING: {
			return {
				...state,
				loading: true
			};
		}
		case CreateGradingActionsTypes.POST_CREATE_GRADING_SUCCESS: {
			return {
				...state,
				create:{success:true, loading: false}
			};
		}	
		case CreateGradingActionsTypes.POST_CREATE_GRADING_ERROR: {
			return {
				...state,
				create:{success:false, loading: false}
			};
		}				

		// ----------------------Update Grading Actions-----------------------
		case UpdateGradingActionsTypes.PUT_UPDATE_GRADING: {
			return {
				...state,
				loading: true
			};
		}
		case UpdateGradingActionsTypes.PUT_UPDATE_GRADING_SUCCESS: {
			const { loading} = action;
			return {
				...state,
				loading, success: true
			};
		}	
		case UpdateGradingActionsTypes.PUT_UPDATE_GRADING_ERROR: {
			const { error, loading } = action;
			return {
				...state,
				loading: false, success: false, error: error
			};
		}				

		// ----------------------Delete Grading Actions-----------------------
		case DeleteGradingActionsTypes.DELETE_GRADING: {
			return {
				...state,
				loading: true
			};
		}
		case DeleteGradingActionsTypes.DELETE_GRADING_SUCCESS: {
			const { loading} = action;
			return {
				...state,
				loading, success: true
			};
		}	
		case DeleteGradingActionsTypes.DELETE_GRADING_ERROR: {
			const { error, loading } = action;
			return {
				...state,
				loading: false, success: false, error: error
			};
		}				
		default:
			return state;
	}
};

export default gradingsReducer;
