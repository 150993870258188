/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../../../components/layouts/HomeLayout/components/MKBox";
import MKTypography from "../../../../../components/layouts/HomeLayout/components/MKTypography";

import { PageTitle } from '../../../../../components/common/PageTitle/PageTitle';

import DefaultNavbar from '../../../../../components/layouts/HomeLayout/examples/Navbars/DefaultNavbar';
import routes from '../../../../../components/layouts/HomeLayout/routes';
import Footer from '../../../../../components/layouts/HomeLayout/examples/Footers/Footer';

const href = window.location.href;
const noIndex = false;
import i18n from '../../../../../locale/i18n';

function Article1() {
    return (
      <>
        <PageTitle title={i18n.t('head:article1Title')} description={i18n.t('head:article1Description')} url={href} noIndex={noIndex}></PageTitle>
      <DefaultNavbar
        routes={routes}
        transparent
      />
        <MKBox component="section" py={8}>
    <Container>
      <Grid container spacing={3} justifyContent="center" alignItems="center" mt={10}>
        <MKTypography variant="h1" ml={3} style={{fontSize: 36}}>{i18n.t('article1:title')}</MKTypography>
        <Grid item xs={12} lg={12}>
        <MKTypography variant="body2" mt={5} ml={5}>{i18n.t('article1:description1')}</MKTypography>
        <MKTypography variant="body2" mt={3} ml={10}>- {i18n.t('article1:description1_2')}</MKTypography>
        <MKTypography variant="body2" ml={10}>- {i18n.t('article1:description1_3')}</MKTypography>
        <MKTypography variant="body2" ml={10}>- {i18n.t('article1:description1_4')}</MKTypography>
        <MKTypography variant="body2" ml={10}>- {i18n.t('article1:description1_5')}</MKTypography>

        <MKTypography variant="body2" mt={5} ml={5}>{i18n.t('article1:description2')}
        <span style={{fontWeight: '600'}}> {i18n.t('article1:omr')}</span>
        {i18n.t('article1:description2_1')}
        </MKTypography>

        <MKTypography variant="h2" mt={5} ml={5} style={{ fontSize: 26 }}>{i18n.t('article1:description3')}</MKTypography>
        <MKTypography variant="body2" mt={3} ml={5}>{i18n.t('article1:description4')}</MKTypography>

        <MKTypography variant="h2" mt={5} ml={5} style={{ fontSize: 26 }}>{i18n.t('article1:description5')}</MKTypography>
        <MKTypography variant="body2" mt={3} ml={5}>{i18n.t('article1:description6')}</MKTypography>
        <MKTypography variant="body2" mt={3} ml={5}>{i18n.t('article1:description7')}</MKTypography>
          </Grid>
      </Grid>
      <Footer/>
    </Container>
  </MKBox>
      </>
    );
  };

export default Article1;