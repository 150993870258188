import React from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import i18n from '../../../locale/i18n';

import { PageTitle } from '../../../components/common/PageTitle/PageTitle';
import { useResponsive } from '../../../hooks/useResponsive';
import * as S from '../Legal.styles';

const href = window.location.href;
const noIndex = true;

const SecurityPracticesPage: React.FC = (props: any) => {
    const { isDesktop } = useResponsive();
    const { t } = useTranslation();
  
    const desktopLayout = (
      <>
      <S.H1 level={1}>Security Practices</S.H1>
      <S.ContentRight>Last Updated: 15 June 2023</S.ContentRight>

      <S.H5 level={2}>Security</S.H5>
      <S.Paragraph>OMR Quiz App uses Amazon EC2 apps servers. Using an industry standard like Amazon helps guarantee the security and resilience of the service. Amazon has attained a number of certifications that affirm that they have stringent, working internal controls.</S.Paragraph>
      
      <S.H5 level={2}>Physical Security</S.H5>
      <S.Paragraph>Amazon safeguards the physical security of their servers. Servers are located in non-descript facilities, and critical facilities have extensive setback and military grade perimeter control. Access to data centers requires two-factor authentication at three points.</S.Paragraph>
      
      <S.H5 level={2}>Data Security</S.H5>
      <S.Paragraph>The host operating system is kept secure by internal processes and procedures. The guest operating system is entirely controlled by OMR Quiz App. Amazon administrators do not have access to it. By default, an inbound firewall denies all traffic to the host servers.</S.Paragraph>
      
      <S.H5 level={2}>Backups</S.H5>
      <S.Paragraph>Amazon systems are built with extensive redundancy. OMR Quiz App backs up data on a daily basis. All access is logged and tracked for auditing purposes.</S.Paragraph>
      
      <S.H5 level={2}>Maintaining Privacy</S.H5>
      <S.Paragraph>Account passwords are encrypted and filtered out from all application and system logs. Passwords are not visible to anyone.</S.Paragraph>
      <S.Paragraph>After 30 minutes of inactivity, you are automatically signed out of your user account.</S.Paragraph>
      <S.Paragraph>Sign out from your account to prevent someone from using your computer to access your account without your authorization. Keep your user name and password secure and not to disclose it to any third-party. In case a third-party gains unauthorized access to your account due to any act or omission on your part, you are solely responsible for any loss caused.</S.Paragraph>
      <S.Paragraph>OMR Quiz may collect from you at your discretion and only when authorized by you, your password to third party accounts with the purpose of linking these accounts in OMR Quiz App. Any such password is used only in accordance with the intended use that is explained before the password is requested from you. Any password you provide is secured in line with our security policies and is not stored on OMR Quiz servers.</S.Paragraph>
      
      <S.H5 level={2}>Credit Card Safety</S.H5>
      <S.Paragraph>OMR Quiz App does not retain any credit card information provided by you. When we charge, such information is transferred directly to, and processed by, our payment gateway.</S.Paragraph>
      <S.Paragraph>To protect user information, OMR Quiz App’s payment gateway uses the latest 256-bit Secure Socket Layer (SSL) technology for secure transactions. The payment gateway is certified as compliant with card association security initiatives including Payment Card Industry (PCI) and Cardholder Information Security Program (CISP) standards for data protection.</S.Paragraph>
      
      <S.H5 level={2}>File Retention Policy</S.H5>
      <S.Paragraph>OMR Quiz App allows customers to optionally upload files to OMR Quiz App’s own storage. None of these files are accessible to any person other than the customer himself. The rules behind how long files are retained is explained in the 
      <S.TextLink to={{ pathname: '/legal/file-retention-policy' }}>
      &nbsp;File Retention Policy
      </S.TextLink>
      .</S.Paragraph>
      
      <S.H5 level={2}>Concerned About Security?</S.H5>
      <S.Paragraph>Read more about 
      <S.TextLink to={{ pathname: '/legal/tos' }}>
      &nbsp;Terms of Service
      </S.TextLink> and 
      <S.TextLink to={{ pathname: '/legal/privacy-policy' }}>
      &nbsp;Privacy Policy
      </S.TextLink>. Do you have a question, concern, or comment about OMR Quiz App security that’s not covered here? Ask on the
      <S.TextLink to={{ pathname: 'https://forum.omrquiz.com/' }}>
      &nbsp;support forums
      </S.TextLink>
      .</S.Paragraph>
      </>
    );
  
    const mobileAndTabletLayout = (
      <Row gutter={[20, 24]}>
        <Col span={24}>
          
        </Col>
  
        <Col span={24}>
          
        </Col>
  
        <Col span={24}>
          
        </Col>
  
        <Col span={24}>
          
        </Col>
      </Row>
    );
  
    return (
      <>
        <PageTitle title={t('head:aboutUsTitle')} description={t('head:aboutUsDescription')} url={href} noIndex={noIndex}></PageTitle>
        {isDesktop ? desktopLayout : mobileAndTabletLayout}
      </>
    );
  };

export default SecurityPracticesPage