import styled from 'styled-components';
import { Table, Typography } from 'antd';
import { FONT_SIZE, FONT_WEIGHT } from '../../styles/themes/constants';
import { Link } from 'react-router-dom';

export const H1 = styled(Typography.Title)`
  &.ant-typography {
    margin-bottom:10;
    font-size: ${FONT_SIZE.xxxxl};
  }
`;

export const H2 = styled(Typography.Title)`
  &.ant-typography {
    margin-bottom: 20px;
    font-size: ${FONT_SIZE.xxxl};
  }
`;

export const H5 = styled(Typography.Title)`
  &.ant-typography {
    margin-bottom: 10px;
    padding-top: 25px;
    font-size: ${FONT_SIZE.md};
  }
`;

export const Paragraph = styled(Typography.Paragraph)`
  &.ant-typography {
    margin-bottom:10;
    font-size: ${FONT_SIZE.xs};
  }
`;

export const ContentRight = styled(Typography.Paragraph)`
  &.ant-typography {
    margin-bottom:10;
    text-align: end;
    font-size: ${FONT_SIZE.xs};
  }
`;

export const ContentLeft = styled(Typography.Paragraph)`
  &.ant-typography {
    margin-bottom:10;

    font-size: ${FONT_SIZE.xs};
  }
`;

export const Span = styled.span`
color: var(--text-main-color);
font-size: ${FONT_SIZE.xs};
font-weight: ${FONT_WEIGHT.bold};
`;

export const Tab = styled(Typography.Paragraph)`
  &.ant-typography {
    margin-bottom:0;
    padding-left:20px;
    font-size: ${FONT_SIZE.xs};
  }
`;

export const TabEnd = styled(Typography.Paragraph)`
  &.ant-typography {
    margin-bottom:0;
    padding-left:20px;
    padding-bottom:20px;
    font-size: ${FONT_SIZE.xs};
  }
`;

export const TextLink = styled(Link)`
  &.ant-typography {
    margin-bottom:0;
    padding-left:20px;
    padding-bottom:20px;
    font-size: ${FONT_SIZE.xxs};
  }
`;