import {
	GetAnswerSheetActionsTypes, GetAnswerSheetsActionsTypes, CreateAnswerSheetActionsTypes, UpdateAnswerSheetActionsTypes, DeleteAnswerSheetActionsTypes,
	IAnswerSheetsState,
	AnswerSheetActions,
} from './types';

const initialState : IAnswerSheetsState = {
	answerSheets: [],
	selectedAnswerSheet:{},
	selectedId: -1,
    loading: false,
    error: '',
    success: false,
	create: {
        loading: false,
        error: '',
        success: false,
    },
	delete: {
        loading: false,
        error: '',
        success: false,
    }
};

const answerSheetsReducer = (state: IAnswerSheetsState = initialState, action: AnswerSheetActions) => {
	switch (action.type) {
		
		// ----------------------Get Answer Sheet Actions-----------------------
		case GetAnswerSheetActionsTypes.GET_ANSWER_SHEET: {
			return {
				...state,
				loading: true
			};
		}
		case GetAnswerSheetActionsTypes.GET_ANSWER_SHEET_SUCCESS: {
			const { loading, data} = action;
			return {
				...state,
				data: data.data,
				loading, success: true
			};
		}
		case GetAnswerSheetActionsTypes.GET_ANSWER_SHEET_ERROR: {
			const { error, loading } = action;
			return {
				...state,
				loading: false, success: false, error: error
			};
		}

		// ----------------------Get Answer Sheets Actions-----------------------
		case GetAnswerSheetsActionsTypes.GET_ANSWER_SHEETS: {
			return {
				...state,
				loading: true
			};
		}
		case GetAnswerSheetsActionsTypes.GET_ANSWER_SHEETS_SUCCESS: {
			const { loading, data} = action;
			return {
				...state,
				loading, 
				success: true,
				answerSheets: data.data.answerSheets
			};
		}	
		case GetAnswerSheetsActionsTypes.GET_ANSWER_SHEETS_ERROR: {
			const { error, loading } = action;
			return {
				...state,
				loading: false, success: false, error: error
			};
		}				

		// ----------------------Create Answer Sheet Actions-----------------------
		case CreateAnswerSheetActionsTypes.POST_CREATE_ANSWER_SHEET: {
			return {
				...state,
				loading: true
			};
		}
		case CreateAnswerSheetActionsTypes.POST_CREATE_ANSWER_SHEET_SUCCESS: {
			return {
				...state,
				create:{success:true, loading: false}
			};
		}	
		case CreateAnswerSheetActionsTypes.POST_CREATE_ANSWER_SHEET_ERROR: {
			return {
				...state,
				create:{success:false, loading: false}
			};
		}				

		// ----------------------Update Answer Sheet Actions-----------------------
		case UpdateAnswerSheetActionsTypes.PUT_UPDATE_ANSWER_SHEET: {
			return {
				...state,
				loading: true
			};
		}
		case UpdateAnswerSheetActionsTypes.PUT_UPDATE_ANSWER_SHEET_SUCCESS: {
			const { loading} = action;
			return {
				...state,
				loading, success: true
			};
		}	
		case UpdateAnswerSheetActionsTypes.PUT_UPDATE_ANSWER_SHEET_ERROR: {
			const { error, loading } = action;
			return {
				...state,
				loading: false, success: false, error: error
			};
		}				

		// ----------------------Delete Answer Sheet Actions-----------------------
		case DeleteAnswerSheetActionsTypes.DELETE_ANSWER_SHEET: {
			return {
				...state,
				loading: true
			};
		}
		case DeleteAnswerSheetActionsTypes.DELETE_ANSWER_SHEET_SUCCESS: {
			const { loading} = action;
			return {
				...state,
				loading, success: true
			};
		}	
		case DeleteAnswerSheetActionsTypes.DELETE_ANSWER_SHEET_ERROR: {
			const { error, loading } = action;
			return {
				...state,
				loading: false, success: false, error: error
			};
		}				
		default:
			return state;
	}
};

export default answerSheetsReducer;
