import React from 'react';
import { Col, Row, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import { PageTitle } from '../../../components/common/PageTitle/PageTitle';
import { useResponsive } from '../../../hooks/useResponsive';
import * as S from '../Legal.styles';

const href = window.location.href;
const noIndex = true;

const SubprocessorsPage: React.FC = (props: any) => {
  const { isDesktop } = useResponsive();
  const { t } = useTranslation();

  const desktopLayout = (
    <>
      <S.H1 level={1}>Subprocessors</S.H1>
      <S.ContentRight>Last Updated: 15 June 2023</S.ContentRight>

      <S.Paragraph>To support delivery of our Services, OMR Quiz may engage and use data processors with access to certain Customer Data (each, a “
        <S.Span>Subprocessor</S.Span>. This page provides important information about the identity, location and role of each Subprocessor.</S.Paragraph>

      <S.H2 level={2}>Third Parties</S.H2>
      <S.Paragraph>OMR Quiz uses third party Subprocessors to provide infrastructure services. Prior to engaging any third party Subprocessor we perform diligence to evaluate their privacy, security and confidentiality practices.</S.Paragraph>

      <S.H5 level={2}>Infrastructure Subprocessors</S.H5>
      <S.Paragraph>OMR Quiz uses the following Subprocessors to host Customer Data or provide other infrastructure that helps with delivery of our Services:</S.Paragraph>

      {/* <S.TableWithoutBorders
      dataSource={dataSource} columns={columns}/> */}

      <table style={{ textAlign: 'left', width: '100%', marginTop: '4vh', marginBottom: '5vh', }}>
        <tr>
          <th><S.Paragraph>Entity Name</S.Paragraph></th>
          <th><S.Paragraph>Subprocessing Activities</S.Paragraph></th>
          <th><S.Paragraph>Entity Country</S.Paragraph></th>
          <th><S.Paragraph>Website</S.Paragraph></th>
        </tr>
        <tr>
          <td><S.Paragraph>Amazon Web Services, Inc.</S.Paragraph></td>
          <td><S.Paragraph>Cloud Service Provider</S.Paragraph></td>
          <td><S.Paragraph>United States</S.Paragraph></td>
          <td><S.Paragraph><a href="https://aws.amazon.com/" rel="alternate">https://aws.amazon.com/</a></S.Paragraph></td>
        </tr>
        <tr>
          <td><S.Paragraph>Google LLC</S.Paragraph></td>
          <td><S.Paragraph>Website Traffic Analytics Provider</S.Paragraph></td>
          <td><S.Paragraph>United States</S.Paragraph></td>
          <td><S.Paragraph><a href="https://analytics.google.com" rel="alternate">https://analytics.google.com</a></S.Paragraph></td>
        </tr>
        <tr>
          <td><S.Paragraph>PayPal Inc</S.Paragraph></td>
          <td><S.Paragraph>Payment Gateway Provider</S.Paragraph></td>
          <td><S.Paragraph>United States</S.Paragraph></td>
          <td><S.Paragraph><a href="https://www.paypal.com/" rel="alternate">https://www.paypal.com/</a></S.Paragraph></td>
        </tr>
        <tr>
          <td><S.Paragraph>Amazon Route 53 (AWS)</S.Paragraph></td>
          <td><S.Paragraph>DNS and Content Distribution</S.Paragraph></td>
          <td><S.Paragraph>United States</S.Paragraph></td>
          <td><S.Paragraph><a href="https://aws.amazon.com/route53/" rel="alternate">https://aws.amazon.com/route53/</a></S.Paragraph></td>
        </tr>
      </table>
      <S.H2 level={2}>Updates</S.H2>
      <S.Paragraph>As our business grows and evolves, the Subprocessors we engage may also change. For our update policy check the 
      <S.TextLink to={{ pathname: '/legal/tos' }}>
      &nbsp;Terms of Service
      </S.TextLink>
      . We will post any such updates here, please check back frequently for updates.</S.Paragraph>
    </>
  );

  const mobileAndTabletLayout = (
    <Row gutter={[20, 24]}>
      <Col span={24}>

      </Col>

      <Col span={24}>

      </Col>

      <Col span={24}>

      </Col>

      <Col span={24}>

      </Col>
    </Row>
  );

  return (
    <>
      <PageTitle title={t('head:aboutUsTitle')} description={t('head:aboutUsDescription')} url={href} noIndex={noIndex}></PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default SubprocessorsPage