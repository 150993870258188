import {
	GetGradingScaleActionsTypes, GetGradingScalesActionsTypes, CreateGradingScaleActionsTypes, UpdateGradingScaleActionsTypes, DeleteGradingScaleActionsTypes,
	IGradingScalesState,
	GradingScaleActions,
} from './types';

const initialState : IGradingScalesState = {
	gradingScales: [],
	selectedGradingScale:[],
	selectedId: -1,
    loading: false,
    error: '',
    success: false,
	create: {
        loading: false,
        error: '',
        success: false,
    },
	delete: {
        loading: false,
        error: '',
        success: false,
    }
};

const gradingScalesReducer = (state: IGradingScalesState = initialState, action: GradingScaleActions) => {
	switch (action.type) {
		
		// ----------------------Get Grading Scale Actions-----------------------
		case GetGradingScaleActionsTypes.GET_GRADING_SCALE: {
			return {
				...state,
				loading: true
			};
		}
		case GetGradingScaleActionsTypes.GET_GRADING_SCALE_SUCCESS: {
			const { loading, data, id} = action;
			return {
				...state,
				selectedGradingScale: data,
				selectedId: id,
				loading, success: true
			};
		}
		case GetGradingScaleActionsTypes.GET_GRADING_SCALE_ERROR: {
			const { error, loading } = action;
			return {
				...state,
				loading: false, success: false, error: error
			};
		}

		// ----------------------Get Grading Scales Actions-----------------------
		case GetGradingScalesActionsTypes.GET_GRADING_SCALES: {
			return {
				...state,
				loading: true
			};
		}
		case GetGradingScalesActionsTypes.GET_GRADING_SCALES_SUCCESS: {
			const { loading, data} = action;
			return {
				...state,
				loading, 
				success: true,
				selectedId: -1,
				gradingScales: data.data.gradingScales,
				delete:{success:false, loading: false},
				create:{success:false, loading: false}
			};
		}	
		case GetGradingScalesActionsTypes.GET_GRADING_SCALES_ERROR: {
			const { error, loading } = action;
			return {
				...state,
				loading: false, success: false, error: error
			};
		}				

		// ----------------------Create Grading Scale Actions-----------------------
		case CreateGradingScaleActionsTypes.POST_CREATE_GRADING_SCALE: {
			return {
				...state,
				loading: true
			};
		}
		case CreateGradingScaleActionsTypes.POST_CREATE_GRADING_SCALE_SUCCESS: {
			return {
				...state,
				create:{success:true, loading: false}
			};
		}	
		case CreateGradingScaleActionsTypes.POST_CREATE_GRADING_SCALE_ERROR: {
			return {
				...state,
				create:{success:false, loading: false}
			};
		}				

		// ----------------------Update Grading Scale Actions-----------------------
		case UpdateGradingScaleActionsTypes.PUT_UPDATE_GRADING_SCALE: {
			return {
				...state,
				loading: true
			};
		}
		case UpdateGradingScaleActionsTypes.PUT_UPDATE_GRADING_SCALE_SUCCESS: {
			const { loading} = action;
			return {
				...state,
				loading, success: true
			};
		}	
		case UpdateGradingScaleActionsTypes.PUT_UPDATE_GRADING_SCALE_ERROR: {
			const { error, loading } = action;
			return {
				...state,
				loading: false, success: false, error: error
			};
		}				

		// ----------------------Delete Grading Scale Actions-----------------------
		case DeleteGradingScaleActionsTypes.DELETE_GRADING_SCALE: {
			return {
				...state,
				loading: true
			};
		}
		case DeleteGradingScaleActionsTypes.DELETE_GRADING_SCALE_SUCCESS: {
			return {
				...state,
				delete:{success:true, loading: false}
			};
		}	
		case DeleteGradingScaleActionsTypes.DELETE_GRADING_SCALE_ERROR: {
			const { error, loading } = action;
			return {
				...state,
				delete:{success:false, loading: false}
			};
		}				
		default:
			return state;
	}
};

export default gradingScalesReducer;
