// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "./components/MKTypography";

// Images
import logo from "../../../assets/logo.svg";

import i18n from '../../../locale/i18n';

const date = new Date().getFullYear();

export default {
  brand: {
    // name: "OMR QUIZ",
    image: logo,
    href: "https://www.omrquiz.com/",
  },
  socials: [
    // {
    //   icon: <FacebookIcon />,
    //   link: "https://www.facebook.com/CreativeTim/",
    // },
    // {
    //   icon: <TwitterIcon />,
    //   link: "https://twitter.com/creativetim",
    // },
    // {
    //   icon: <GitHubIcon />,
    //   link: "https://github.com/creativetimofficial",
    // },
    // {
    //   icon: <YouTubeIcon />,
    //   link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w",
    // },
  ],
  menus: [
    {
      name: i18n.t('footer:company'),
      items: [
        { name: i18n.t('footer:product'), href: "https://products.omrquiz.com/" },
        // { name: "freebies", href: "https://www.creative-tim.com/templates/free" },
        // { name: "premium tools", href: "https://www.creative-tim.com/templates/premium" },
        // { name: "blog", href: "https://www.creative-tim.com/blog" },
      ],
    },
    // {
    //   name: "resources",
    //   items: [
    //     { name: "Library", href: "https://products.aspose.com/omr/net/" },
    //     // { name: "bits & snippets", href: "https://www.creative-tim.com/bits" },
    //     // { name: "affiliate program", href: "https://www.creative-tim.com/affiliates/new" },
    //   ],
    // },
    {
      name: i18n.t('footer:helpSupport'),
      items: [
        { name: i18n.t('footer:contactUs'), href: "https://about.omrquiz.com/contact" },
        // { name: "knowledge center", href: "https://www.creative-tim.com/knowledge-center" },
        // { name: "custom development", href: "https://services.creative-tim.com/" },
        // { name: "sponsorships", href: "https://www.creative-tim.com/sponsorships" },
      ],
    },
    {
      name: i18n.t('footer:legal'),
      items: [
        { name: i18n.t('footer:terms'), href: "https://about.omrquiz.com/legal/tos" },
        { name: i18n.t('footer:privacy'), href: "https://about.omrquiz.com/legal/privacy-policy" },
        // { name: "licenses (EULA)", href: "https://www.conholdate.app/viewer/view/vqoiNMKMY9sg8DL1R/aspose_end-user-license-agreement_2023-05-04.pdf?preview=true.pdf" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
       © 2022-{date} Smallize Pty Ltd. {i18n.t('footer:allRight')}
      <MKTypography
        component="a"
        variant="button"
        fontWeight="regular"
      >
      </MKTypography>
    </MKTypography>
  ),
};
