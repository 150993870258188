import { takeLatest, put, call, select } from 'redux-saga/effects';
import { apiRequest } from '../../apiRequest';
import { URLs } from '../../urls';

import { GetClassActionsTypes, IGetClassResult, GetClassRequestSucces, GetClassRequestError,
	GetStudentActionsTypes, IGetStudentResult, GetStudentRequestSucces, GetStudentRequestError,
	CreateStudentActionsTypes, ICreateStudentResult, CreateStudentRequestSucces, CreateStudentRequestError,
	UpdateStudentActionsTypes, IUpdateStudentResult, UpdateStudentRequestSucces, UpdateStudentRequestError,
	DeleteStudentActionsTypes, IDeleteStudentResult, DeleteStudentRequestSucces, DeleteStudentRequestError,
} from './types';

const id = localStorage.getItem("UserId");

function* sendGetClassRequest(action: any) {
	try {
		// API call
		const response : IGetClassResult = yield call(apiRequest, {
			endpoint: URLs.GET_CLASS_ENDPOINT + "/" + action.id,
			method: 'GET',
			
		});
		if (response.success == true) {
			let putData: GetClassRequestSucces = {
				type: GetClassActionsTypes.GET_CLASS_SUCCESS,
				data: response.data.students,
				id: response.data.id,
				loading: false,
			};
			yield put<GetClassRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: GetClassRequestError = {
				type: GetClassActionsTypes.GET_CLASS_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<GetClassRequestError>(putData);
		}

	} catch (e: any) {
		let putData: GetClassRequestError = {
			type: GetClassActionsTypes.GET_CLASS_ERROR,
			error: e,
			loading: false
		};
		yield put<GetClassRequestError>(putData);
	}
}

function* sendGetStudentRequest(action: any) {
	try {
		// API call
		const response : IGetStudentResult = yield call(apiRequest, {
			endpoint: URLs.GET_STUDENT_ENDPOINT + "/" + action.id, 
			method: 'GET',
			
		});
		if (response.success == true) {
			let putData: GetStudentRequestSucces = {
				type: GetStudentActionsTypes.GET_STUDENT_SUCCESS,
				data: response.data.studentExamItems,
				id: response.data.id,
				loading: false,
			};
			yield put<GetStudentRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: GetStudentRequestError = {
				type: GetStudentActionsTypes.GET_STUDENT_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<GetStudentRequestError>(putData);
		}

	} catch (e: any) {
		let putData: GetStudentRequestError = {
			type: GetStudentActionsTypes.GET_STUDENT_ERROR,
			error: e,
			loading: false
		};
		yield put<GetStudentRequestError>(putData);
	}
}

function* sendCreateStudentRequest(action: any) {
	try {
		// API call
		const response : ICreateStudentResult = yield call(apiRequest, {
			endpoint: URLs.CREATE_STUDENT_ENDPOINT + "/" + id,
			method: 'POST',
			body: action.model
		});
		if (response.success == true) {
			let putData: CreateStudentRequestSucces = {
				type: CreateStudentActionsTypes.POST_CREATE_STUDENT_SUCCESS,
				data: response,
				loading: false,
				success: response.success
			};
			yield put<CreateStudentRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: CreateStudentRequestError = {
				type: CreateStudentActionsTypes.POST_CREATE_STUDENT_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<CreateStudentRequestError>(putData);
		}

	} catch (e: any) {
		let putData: CreateStudentRequestError = {
			type: CreateStudentActionsTypes.POST_CREATE_STUDENT_ERROR,
			error: e,
			loading: false
		};
		yield put<CreateStudentRequestError>(putData);
	}
}

function* sendUpdateStudentRequest(action: any) {
	try {
		// API call
		const response : IUpdateStudentResult = yield call(apiRequest, {
			endpoint: URLs.UPDATE_STUDENT_ENDPOINT,
			method: 'PUT',
			body: action.model
		});

		if (response.success == true) {
			let putData: UpdateStudentRequestSucces = {
				type: UpdateStudentActionsTypes.PUT_UPDATE_STUDENT_SUCCESS,
				data: response,
				loading: false,
			};
			yield put<UpdateStudentRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: UpdateStudentRequestError = {
				type: UpdateStudentActionsTypes.PUT_UPDATE_STUDENT_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<UpdateStudentRequestError>(putData);
		}

	} catch (e: any) {
		let putData: UpdateStudentRequestError = {
			type: UpdateStudentActionsTypes.PUT_UPDATE_STUDENT_ERROR,
			error: e,
			loading: false
		};
		yield put<UpdateStudentRequestError>(putData);
	}
}

function* sendDeleteStudentRequest(action: any) {
	try {
		// API call
		const response : IDeleteStudentResult = yield call(apiRequest, {
			endpoint: URLs.DELETE_STUDENT_ENDPOINT + "/" + action.id,
			method: 'DELETE',
			body: action.model
		});

		if (response.success == true) {
			let putData: DeleteStudentRequestSucces = {
				type: DeleteStudentActionsTypes.DELETE_STUDENT_SUCCESS,
				data: response,
				loading: false,
				success: response.success
			};
			yield put<DeleteStudentRequestSucces>(putData);
		}

		if (response.success == false) {
			let putData: DeleteStudentRequestError = {
				type: DeleteStudentActionsTypes.DELETE_STUDENT_ERROR,
				error: response.error.toString(),
				loading: false
			};
			yield put<DeleteStudentRequestError>(putData);
		}

	} catch (e: any) {
		let putData: DeleteStudentRequestError = {
			type: DeleteStudentActionsTypes.DELETE_STUDENT_ERROR,
			error: e,
			loading: false
		};
		yield put<DeleteStudentRequestError>(putData);
	}
}

export function* handler() {
	yield takeLatest(GetClassActionsTypes.GET_CLASS, sendGetClassRequest);
	yield takeLatest(GetStudentActionsTypes.GET_STUDENT, sendGetStudentRequest);
	yield takeLatest(CreateStudentActionsTypes.POST_CREATE_STUDENT, sendCreateStudentRequest);
	yield takeLatest(UpdateStudentActionsTypes.PUT_UPDATE_STUDENT, sendUpdateStudentRequest);
	yield takeLatest(DeleteStudentActionsTypes.DELETE_STUDENT, sendDeleteStudentRequest);
}

