//import login from "./login.json";
import common from "./common.json";
//import sidebar from "./sidebar.json";
import popconfirm from "./popconfirm.json";
//import classes from "./classes.json";
import home from "./home.json";
import head from "./head.json";
import header from "./header.json";
//import scales from "./scales.json";
//import answersheets from "./answersheets.json";
//import product from "./product.json";
import team from "./team.json";
import featuring from "./featuring.json";
import newsletter from "./newsletter.json";
import footer from "./footer.json";
//import assessments from "./assessments.json";
//import grading from "./grading.json";
import createanswersheet from "./createanswersheet.json";
import scananswersheet from "./scananswersheet.json";
import gradecalculator from "./gradecalculator.json";
import productshome from "./productshome.json";
import docshome from "./docs.json";
import article1 from "./article1.json";
import article2 from "./article2.json";
import createsurvey from "./createsurvey.json";
import scansurvey from "./scansurvey.json";
//import howTo from "./howTo.json";
import gradecalculatorcountries from "./gradecalculatorcountries.json";
import gradecalculatorsystems from "./gradecalculatorsystems.json";
import wizard from "./wizard.json";

export default {
    //login,
    common,
    //sidebar,
    popconfirm,
    //classes,
    home,
    header,
    //scales,
    //answersheets,
    //product,
    team,
    featuring,
    newsletter,
    footer,
    head,
    //assessments,
    //grading,
    createanswersheet,
    scananswersheet,
    gradecalculator,
    productshome,
    docshome,
    article1,
    article2,
    createsurvey,
    scansurvey,
    //howTo,
    gradecalculatorcountries,
    gradecalculatorsystems,
    wizard
}