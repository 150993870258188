import React from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { PageTitle } from '../../../components/common/PageTitle/PageTitle';
import { useResponsive } from '../../../hooks/useResponsive';
import * as S from '../Legal.styles';
import i18n from '../../../locale/i18n';

const href = window.location.href;
const noIndex = true;

const LegalPage: React.FC = (props: any) => {
    const { isDesktop } = useResponsive();
    const { t } = useTranslation();
  
    const desktopLayout = (
      <>
      <S.H1 level={1}>Legal</S.H1>
      <S.Paragraph>In this section you can find detailed legal information regarding various terms and policies for OMR Quiz App.</S.Paragraph>

      <S.H2 level={2}>
      <S.TextLink to={{ pathname: '/legal/tos/' }}>Terms of Service</S.TextLink>
      </S.H2>
      <S.Paragraph>By accessing, browsing, and/or using any of our websites, you acknowledge that you have read, understood, and agree to be bound by these terms and to comply with all applicable laws and regulations. 
      <S.TextLink to={{ pathname: '/legal/tos/' }}> More...</S.TextLink>
      </S.Paragraph>

      <S.H2 level={2}>
      <S.TextLink to={{ pathname: '/legal/security/' }}>Security Practices</S.TextLink>
      </S.H2>
      <S.Paragraph> OMR Quiz takes security very seriously. Learn what steps we take to ensure your data is secure
      <S.TextLink to={{ pathname: '/legal/security/' }}> More...</S.TextLink>
      </S.Paragraph>

      <S.H2 level={2}>
      <S.TextLink to={{ pathname: '/legal/file-retention-policy/' }}>File Retention Policy</S.TextLink>
      </S.H2>
      <S.Paragraph> Details about we how we treat retention of those files for both non account and account users.
      <S.TextLink to={{ pathname: '/legal/file-retention-policy/' }}> More...</S.TextLink>
      </S.Paragraph>

      <S.H2 level={2}>
      <S.TextLink to={{ pathname: '/legal/acceptable-use/' }}>Acceptable Use Policy</S.TextLink>
      </S.H2>
      <S.Paragraph> Use of the OMR Quiz API is subject to this acceptable use policy.
      <S.TextLink to={{ pathname: '/legal/acceptable-use/' }}> More...</S.TextLink>
      </S.Paragraph>

      <S.H2 level={2}>
      <S.TextLink to={{ pathname: '/legal/subprocessors/' }}>Subprocessors</S.TextLink>
      </S.H2>
      <S.Paragraph> This page provides important information about the identity, location and role of each Subprocessor OMR Quiz uses.
      <S.TextLink to={{ pathname: '/legal/subprocessors/' }}> More...</S.TextLink>
      </S.Paragraph>

      <S.H2 level={2}>
      <S.TextLink to={{ pathname: '/legal/copyright-notice/' }}>Copyright Notice</S.TextLink>
      </S.H2>
      <S.Paragraph> The complete copyright notice detailing the rights of OMR Quiz with regards to the contents of the website and description of all the trademarks.
      <S.TextLink to={{ pathname: '/legal/copyright-notice/' }}> More...</S.TextLink>
      </S.Paragraph>

      <S.H2 level={2}>
      <S.TextLink to={{ pathname: '/legal/privacy-policy/' }}>Privacy Policy</S.TextLink>
      </S.H2>
      <S.Paragraph> A detailed description of the OMR Quiz privacy policy with regards to the users of the OMR Quiz website.
      <S.TextLink to={{ pathname: '/legal/privacy-policy/' }}> More...</S.TextLink>
      </S.Paragraph>

      <S.H2 level={2}>
      <S.TextLink to={{ pathname: '/legal/gdpr/' }}>GDPR Public Policy</S.TextLink>
      </S.H2>
      <S.Paragraph> Review our dedicated page to learn more about our approach to GDPR.
      <S.TextLink to={{ pathname: '/legal/gdpr/' }}> More...</S.TextLink>
      </S.Paragraph>

      <S.H2 level={2}>
      <S.TextLink to={{ pathname: '/legal/dmca-policy/' }}>DMCA Policy</S.TextLink>
      </S.H2>
      <S.Paragraph> Our policy surrounding copyrighted works or materials improperly accessible on our website (DMCA).
      <S.TextLink to={{ pathname: '/legal/dmca-policy/' }}> More...</S.TextLink>
      </S.Paragraph>

      <S.H2 level={2}>
      <S.TextLink to={{ pathname: '/legal/paperless-policy/' }}>Paperless Policy</S.TextLink>
      </S.H2>
      <S.Paragraph> OMR Quiz is a 100% electronic company and does not accept any physical documents.
      <S.TextLink to={{ pathname: '/legal/paperless-policy/' }}> More...</S.TextLink>
      </S.Paragraph>
      </>
    );
  
    const mobileAndTabletLayout = (
      <Row gutter={[20, 24]}>
        <Col span={24}>
          
        </Col>
  
        <Col span={24}>
          
        </Col>
  
        <Col span={24}>
          
        </Col>
  
        <Col span={24}>
          
        </Col>
      </Row>
    );
  
    return (
      <>
        <PageTitle title={t('head:aboutUsTitle')} description={t('head:aboutUsDescription')} url={href} noIndex={noIndex}></PageTitle>
        {isDesktop ? desktopLayout : mobileAndTabletLayout}
      </>
    );
  };

export default LegalPage