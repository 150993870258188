import React, { useState } from 'react';
//import { Header } from '../../../header/Header';
import AboutSider from './sider/AboutSider';
import AboutContent from './AboutContent';
import * as S from './AboutLayout.styles';
import { Outlet, useLocation } from 'react-router-dom';
import { useResponsive } from '../../../hooks/useResponsive';
import DefaultNavbar from '../HomeLayout/examples/Navbars/DefaultNavbar';
import i18n from '../../../locale/i18n';
import routes from '../HomeLayout/routes';
import { Layout, Row, Col } from 'antd';
import { ScrollWrapper } from '../../../pages/classes/ClassesPage.styles';
import MKBox from "../HomeLayout/components/MKBox";
import DefaultFooter from '../HomeLayout/examples/Footers/DefaultFooter';
import footerRoutes from "../HomeLayout/footer.routes";
import MKTypography from '../HomeLayout/components/MKTypography';

const { Content } = Layout;

const AboutLayout: React.FC = (props: any) => {
  const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true);
  const [siderCollapsed, setSiderCollapsed] = useState(true);
  const { isDesktop } = useResponsive();
  const location = useLocation();
  const date = new Date().getFullYear();

  const toggleSider = () => setSiderCollapsed(!siderCollapsed);

  // useEffect(() => {
  //   setIsTwoColumnsLayout([MEDICAL_DASHBOARD_PATH, NFT_DASHBOARD_PATH].includes(location.pathname) && isDesktop);
  // }, [location.pathname, isDesktop]);

  return (
    <>
      <DefaultNavbar
        routes={routes}
        // action={{
        //   type: "external",
        //   route: "https://products.omrquiz.com/panel/my-classes",
        //   label: i18n.t('header:myClasses'),
        //   color: "info",
        // }}
        //sticky
      />

      <Layout style={{ maxHeight: '100vh' }}>
        <Row style={{ height: '100vh' }}>
          <Col span={4} style={{ maxHeight: '100vh' }}>
            <AboutSider />
          </Col>
          <Col span={18} style={{ maxHeight: '100vh', overflow: 'scroll' }}>
            {/* <Layout className="site-layout"> */}
            <Layout>
              <Content style={{ marginTop: '15vh' }}>
                <Outlet />
              </Content>
            </Layout>
            <div style={{ textAlign: 'center', marginTop: '5vh' }}>
              <S.Paragraph>
                <S.TextLink to={{ pathname: '/contact' }}>
                  Contact
                </S.TextLink>&nbsp;&nbsp;&nbsp;|
                <S.TextLink to={{ pathname: '/legal/tos' }}>
                  &nbsp;&nbsp;&nbsp;Terms Of Service
                </S.TextLink>&nbsp;&nbsp;&nbsp;|
                <S.TextLink to={{ pathname: '/legal/privacy-policy' }}>
                  &nbsp;&nbsp;&nbsp;Privacy Policy
                </S.TextLink>&nbsp;&nbsp;&nbsp;|
                <S.TextLink to={{ pathname: '/' }}>
                  &nbsp;&nbsp;&nbsp;About
                </S.TextLink>
              </S.Paragraph>
              {/* <S.Paragraph>© 2022-{date} Smallize Pty Ltd. {i18n.t('footer:allRight')}</S.Paragraph> */}
              <S.Paragraph>© 2022-{date} Smallize Pty Ltd. All rights reserved.</S.Paragraph>
            </div>
          </Col>
        </Row>
      </Layout>
    </>
  );
};

export default AboutLayout;