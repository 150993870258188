import {
	GetClassesActionsTypes, CreateClassActionsTypes, UpdateClassActionsTypes, DeleteClassActionsTypes,
	IClassesState,
	ClassActions,
} from './types';

const initialState : IClassesState = {
	classes: [],
    loading: false,
    error: '',
    success: false,
	create: {
        loading: false,
        error: '',
        success: false,
    },
	delete: {
        loading: false,
        error: '',
        success: false,
    }
};

const classesReducer = (state: IClassesState = initialState, action: ClassActions) => {
	switch (action.type) {

		// ----------------------Get Classes Actions-----------------------
		case GetClassesActionsTypes.GET_CLASSES: {
			return {
				...state,
				loading: true
			};
		}
		case GetClassesActionsTypes.GET_CLASSES_SUCCESS: {
			const { loading, data} = action;
			return {
				...state,
				loading, 
				success: true,
				classes: data.data.classes,
				create:{success:false, loading: false}
			};
		}	
		case GetClassesActionsTypes.GET_CLASSES_ERROR: {
			const { error, loading } = action;
			return {
				...state,
				loading: false, success: false, error: error
			};
		}				

		// ----------------------Create Class Actions-----------------------
		case CreateClassActionsTypes.POST_CREATE_CLASS: {
			return {
				...state,
				loading: true
			};
		}
		case CreateClassActionsTypes.POST_CREATE_CLASS_SUCCESS: {
			return {
				...state, 
				create:{success:true, loading: false}
			};
		}	
		case CreateClassActionsTypes.POST_CREATE_CLASS_ERROR: {
			return {
				...state,
				create:{success:false, loading: false}
			};
		}				

		// ----------------------Update Class Actions-----------------------
		case UpdateClassActionsTypes.PUT_UPDATE_CLASS: {
			return {
				...state,
				loading: true
			};
		}
		case UpdateClassActionsTypes.PUT_UPDATE_CLASS_SUCCESS: {
			const { loading} = action;
			return {
				...state,
				loading, success: true
			};
		}	
		case UpdateClassActionsTypes.PUT_UPDATE_CLASS_ERROR: {
			const { error, loading } = action;
			return {
				...state,
				loading: false, success: false, error: error
			};
		}				

		// ----------------------Delete Class Actions-----------------------
		case DeleteClassActionsTypes.DELETE_CLASS: {
			return {
				...state,
				loading: true
			};
		}
		case DeleteClassActionsTypes.DELETE_CLASS_SUCCESS: {
			return {
				...state,
				delete:{success:true, loading: false}
			};
		}	
		case DeleteClassActionsTypes.DELETE_CLASS_ERROR: {
			return {
				...state,
				delete:{success:false, loading: false}
			};
		}				
		default:
			return state;
	}
};

export default classesReducer;
