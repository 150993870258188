import * as S from './styles';
import { Link } from "react-router-dom";
import logo from "../../../../../../assets/logo.svg";
import MKBox from "../../../components/MKBox";
import Grid from "@mui/material/Grid";

import i18n from '../../../../../../locale/i18n';

function Footer() {
    const date = new Date().getFullYear();
    return (
        <div style={{ textAlign: 'center', marginTop: '5vh' }}>
            <S.Paragraph>
                <S.TextLink to={{ pathname: '/contact' }}>
                {i18n.t('common:contact')}
                </S.TextLink>&nbsp;&nbsp;&nbsp;|
                <S.TextLink to={{ pathname: '/legal/tos' }}>
                    &nbsp;&nbsp;&nbsp;{i18n.t('common:termsOfService')}
                </S.TextLink>&nbsp;&nbsp;&nbsp;|
                <S.TextLink to={{ pathname: '/legal/privacy-policy' }}>
                    &nbsp;&nbsp;&nbsp;{i18n.t('common:privacyPolicy')}
                </S.TextLink>&nbsp;&nbsp;&nbsp;|
                <S.TextLink to={{ pathname: '/' }}>
                    &nbsp;&nbsp;&nbsp;{i18n.t('common:about')}
                </S.TextLink>
            </S.Paragraph>
            {/* <S.Paragraph>© 2022-{date} Smallize Pty Ltd. {i18n.t('footer:allRight')}</S.Paragraph> */}
            <S.Paragraph>© 2022-{date} Smallize Pty Ltd. {i18n.t('common:allRights')}.</S.Paragraph>
        </div>
    );
}

export default Footer;